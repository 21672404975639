import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetail, retiredOrder } from "../../../redux/actions";
import styles from "./Repartidor.module.css";
import TopBanner from "../TopBanner";
import pedidoEntregado from "../../../assets/LandingNegocio/pedidoEntregado.png";
import error404 from "../../../assets/LandingNegocio/error404.png";
import pedidoCancelado from "../../../assets/LandingNegocio/cancelar.png";
import mapa from "../../../assets/LandingNegocio/mapa.png";
import retirado from "../../../assets/LandingNegocio/retirado.png";
import entregado from "../../../assets/LandingNegocio/entregado.png";
import aprobado from "../../../assets/aprobado.png";
import whatsapp from "../../../assets/ws.png";

const Repartidor = () => {
  const dispatch = useDispatch();
  let { nroOrden } = useParams();
  const [stateRetirado, setStateRetirado] = useState();
  const [stateEntregado, setStateEntregado] = useState();
  const [buttonFunctionEntregado, setButtonFunctionEntregado] = useState(
    styles.buttons
  );
  const [buttonFunctionRetirado, setButtonFunctionRetirado] = useState(
    styles.buttons
  );
  const orderDetail = useSelector((state) => state.orderDetail[0]);

  useEffect(() => {
    if (!orderDetail) {
      dispatch(getOrderDetail(parseInt(nroOrden)));
    }
  }, [orderDetail, nroOrden, dispatch]);

  function handleRetirado(id) {
    setButtonFunctionRetirado(styles.buttonDis);
    let estado = "Retirado";
    dispatch(retiredOrder(id, estado));
  }

  function handleEntregado(id) {
    setButtonFunctionEntregado(styles.buttonDis);
    let estado = "Entregado";
    dispatch(retiredOrder(id, estado));
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  const openMaps = () => {
    const direccionEntregaCodificada = encodeURIComponent(
      orderDetail && orderDetail.direccionEntrega
    );
    const direccionRetiroCodificada = encodeURIComponent(
      orderDetail && orderDetail.direccionRetiro
    );
    const enlaceGoogleMaps =
      "https://www.google.com/maps/dir/?api=1&origin=&destination=" +
      direccionEntregaCodificada +
      "&travelmode=driving&waypoints=" +
      direccionRetiroCodificada;
    window.open(enlaceGoogleMaps);
  };

  const openOneDirection = (direccion) => {
    const direccionCodificada = encodeURIComponent(direccion);
    const enlaceGoogleMaps =
      "https://www.google.com/maps/dir/?api=1&origin=&destination=" +
      direccionCodificada +
      "&travelmode=driving";
    window.open(enlaceGoogleMaps);
  };

  const openWsChat = (numero) => {
    const mensaje =
      "Hola, soy repartidor de Blikz y estoy encargado de llevar su pedido. Quería preguntarle..";
    const mensajeCodificado = encodeURIComponent(mensaje);
    window.open(`https://wa.me/52${numero}?text=${mensajeCodificado}`);
  };

  return (
    <>
      <div className={styles.topBanner}>
        <TopBanner />
      </div>
      <>
        {orderDetail && nroOrden !== "undefined" ? (
          <>
            {!orderDetail.cancelado ? (
              <>
                {orderDetail.status === "Entregado" ? (
                  <>
                    <div className={styles.pedidoEntregado}>
                      <img
                        src={pedidoEntregado}
                        alt="Pedido Entregado"
                        className={styles.imgEntregado}
                      />
                      <h3 className={styles.mensaje}>
                        ¡Tu pedido ha sido entregado con éxito!
                      </h3>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.gral}>
                      <h2 className={styles.title}>Detalles del pedido</h2>
                      <div className={styles.infoDiv}>
                        <h4 className={styles.subtitle}>Direccion de retiro</h4>
                        <div
                          onClick={() =>
                            openOneDirection(orderDetail.direccionRetiro)
                          }
                        >
                          <h4 className={styles.link}>
                            {orderDetail.direccionRetiro}
                          </h4>
                        </div>
                      </div>
                      <div className={styles.infoDiv}>
                        <h4 className={styles.subtitle}>Teléfono de retiro</h4>
                        <div className={styles.numeroTelefonoDiv}>
                          <h4 className={styles.info}>
                            {orderDetail.telefonoNegocio}
                          </h4>
                          <div
                            onClick={() =>
                              openWsChat(orderDetail.telefonoNegocio)
                            }
                          >
                            <img
                              src={whatsapp}
                              alt="barra chat"
                              height={"20px"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.infoDiv}>
                        <h4 className={styles.subtitle}>
                          Direccion de entrega
                        </h4>
                        <div
                          onClick={() =>
                            openOneDirection(orderDetail.direccionEntrega)
                          }
                        >
                          <h4 className={styles.link}>
                            {orderDetail.direccionEntrega}
                          </h4>
                        </div>
                      </div>
                      {orderDetail.nombreCliente !== "undefined" && (
                        <div className={styles.infoDiv}>
                          <h4 className={styles.subtitle}>
                            Nombre del que recibe
                          </h4>
                          <h4 className={styles.info}>
                            {orderDetail.nombreCliente}
                          </h4>
                        </div>
                      )}
                      <div className={styles.infoDiv}>
                        <h4 className={styles.subtitle}>
                          Teléfono del que recibe
                        </h4>
                        <div className={styles.numeroTelefonoDiv}>
                          <h4 className={styles.info}>
                            {orderDetail.telefonoCliente}
                          </h4>
                          <div
                            onClick={() =>
                              openWsChat(orderDetail.telefonoCliente)
                            }
                          >
                            <img
                              src={whatsapp}
                              alt="barra chat"
                              height={"20px"}
                            />
                          </div>
                        </div>
                      </div>
                      {orderDetail.detallesExtra !== "Vacio" && (
                        <div className={styles.infoDiv}>
                          <h4 className={styles.subtitle}>Instrucciones</h4>
                          <h4 className={styles.info}>
                            {orderDetail.detallesExtra}
                          </h4>
                        </div>
                      )}
                      {/* <MapView twoPoints={true} /> */}
                      <div className={styles.divButtons}>
                        <button
                          className={styles.buttons}
                          onClick={() => openMaps()}
                        >
                          <img
                            src={mapa}
                            alt="mapa"
                            className={styles.imgButtons}
                          />
                          <h4>Iniciar recorrido</h4>
                        </button>
                        <button
                          className={buttonFunctionRetirado}
                          onClick={() => {
                            setStateRetirado(true);
                            setButtonFunctionRetirado(styles.buttonDis);
                            handleRetirado(nroOrden);
                          }}
                          disabled={
                            orderDetail.status === "Retirado" ? true : false
                          }
                        >
                          {orderDetail.status === "Retirado" ||
                          stateRetirado ? (
                            <img
                              src={aprobado}
                              alt="retirado"
                              className={styles.imgButtons}
                            />
                          ) : (
                            <img
                              src={retirado}
                              alt="retirado"
                              className={styles.imgButtons}
                            />
                          )}
                          <h4>Pedido retirado</h4>
                        </button>
                        <button
                          className={buttonFunctionEntregado}
                          onClick={() => {
                            setStateEntregado(true);
                            setButtonFunctionEntregado(styles.buttonDis);
                            handleEntregado(nroOrden);
                          }}
                          disabled={stateEntregado}
                        >
                          {stateEntregado ? (
                            <img
                              src={aprobado}
                              alt="retirado"
                              height="80px"
                              className={styles.imgButtons}
                            />
                          ) : (
                            <img
                              src={entregado}
                              alt="entregado"
                              height="120px"
                              className={styles.imgButtons}
                            />
                          )}
                          <h4>Pedido Entregado</h4>
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className={styles.pedidoEntregado}>
                  <img src={pedidoCancelado} alt="Pedido Cancelado" />
                  <h3 className={styles.mensaje}>
                    ¡Tu pedido ha sido cancelado!
                  </h3>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className={styles.gralError}>
              <img src={error404} alt="ERORR 404" height="220px" />
              <h3>
                No encontramos ningún pedido en curso con este número de orden.
              </h3>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default Repartidor;
