import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./AdminHome.module.css";
import ShopInfo from "./ShopInfo/ShopInfo";
import Message from "./Message/Message";
import AdminShipping from "./AdminShipping/AdminShipping";
import { getUserInfo } from "../../../redux/actions";
import ButtonNewOrder from "./ButtonNewOrder/ButtonNewOrder";
import SliderNegocioCurso from "./SliderNegocioCurso/SliderNegocioCurso";
import SliderNegocioFinalizado from "./SliderNegocioFinalizado/SliderNegocioFinalizado";
import Header from "./Header/Header";

export default function AdminDashboard() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (!userData.id) {
      const email = JSON.parse(localStorage.getItem("Mail-Usuario-Logueado"));
      dispatch(getUserInfo(email));
    }
  }, [dispatch, userData]);

  return (
    <>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.gral}>
        <div className={styles.main}>
        <div className={styles.messageDesktop}>
            <Message />
          </div>
          <div className={styles.shopInfo}>
            <ShopInfo
              negocio={userData.negocio}
              imagen={userData.imagen}
              id={userData.id}
            />
          </div>
          <div className={styles.directAccess}>
            <ButtonNewOrder />
          </div>
          <div className={styles.messageMobile}>
            <Message />
          </div>
          <div className={styles.sliderMobile}>
            <h1 className={styles.misEnviosMobile}>Envíos en curso</h1>
            <SliderNegocioCurso />
            {/* </div> */}
            {/* <div className={styles.sliderMobile}> */}
            <h1 className={styles.misEnviosMobile}>Envíos finalizados</h1>
            <SliderNegocioFinalizado />
          </div>
          <div className={styles.tableDesktop}>
            <h1 className={styles.misEnvios}>Mis envíos</h1>
            <div className={styles.adminShipping}>
              <AdminShipping id={userData.id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
