import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CardsSeguimiento.module.css";
import { getAllOrders } from "../../../../redux/actions";
import { Link } from "react-router-dom";

// import noimage from "../../../assets/imgdft.png";
// { name, price, offerPrice, image } esto es lo que recibe por props Cards
export default function Cards() {
  const dispatch = useDispatch();
  const idLanding = JSON.parse(localStorage.getItem("idLanding"));
  const allOrders = useSelector((state) => state.allOrders);

  useEffect(() => {     
    if (!allOrders[0]) {
      dispatch(getAllOrders(idLanding));
    }
  }, [allOrders, dispatch, idLanding]);
  
  
  // const allOrdersFilteredAll = [JSON.parse(localStorage.getItem("envios"))];
  // const allOrdersFiltered = allOrdersFilteredAll? allOrdersFilteredAll.filter(
  //   (el) => el.status !== "Finalizado" && el.status !== "Cancelado"
  // ):null;
  const allOrdersFiltered = allOrders[0]? allOrders[0].filter(
    (el) =>
      el.clienteIp === JSON.parse(localStorage.getItem("ipClienteLs"))
      &&
      el.status !== "Cancelado" &&
      el.status !== "Entregado"
  ):undefined
 
  return (
    <>
      {allOrders[0] && allOrdersFiltered[0]? (  
        allOrdersFiltered.map((el) => (
          <Link
            // onclick={window.scrollTo(0, 0)}
            className={styles.cart}
            to={"/seguimiento/" + el.id}
            // to="/"
            key={el.id}
          >
            {el.id !== null && (
              <div className={styles.Card} key={el.id}>
                <h3 className={styles.name}>#Orden {el.id}</h3>
              </div>
            )}
          </Link>
        ))
      ) : (
        <h3 className={styles.sinPedidos}>No hay pedidos en curso</h3>
      )}
    </>
  );
}
