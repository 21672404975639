import React from "react";
// import { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from './ChangePassword.module.css'
// import logo from "../../../../assets/logo.png";
import { changePassword } from "../../../../redux/actions";
import MyInput from "../../../basics/myInput";
import { deleteToken } from "../../../Login/controllers/tokenFunctions";


export default function ChangePassword(props){
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const changePasswordState = useSelector((state) => state.changePassword);
    const [input, setInput] = React.useState({
        password: "",
        newPassword: ""
    });

    function handleChange(e) {
        setInput({
        ...input, //ademas de lo que tiene
        [e.target.name]: e.target.value, //agregale lo que el usuario pone
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        if(input.newPassword.length > 4){
        dispatch(changePassword(props.id, input.password ,input.newPassword))
        setInput({
            password: "",
            newPassword: ""
        });
       
    }else{
        alert('La nueva contraseña debe tener: Mínimo 4 caracteres ');
     }
        // navigate('');
    }

    if(changePasswordState){
        alert('¡Contraseña cambiada con éxito!');
        props.setTrigger(false)
        deleteToken();
        localStorage.removeItem("Mail-Usuario-Logueado");
        window.location.replace("/admin");
    }

    return (props.trigger) ? (
        <div className={styles.popup}>
            {props.children}
            <form onSubmit={(e) => {handleSubmit(e)}} className={styles.form}>
            {/* <img src={logo} alt="Blikz" height="45px"/> */}
                <div className={styles.divTitle}>
                <h1 className={styles.title}>Cambiar Contraseña</h1>
                <button className={styles.close} onClick={() => props.setTrigger(false)}>x</button>
                </div>
                {/* <div className={styles.div_input}>
                    <label>Email:</label><br/>
                    <input 
                        value={input.email}
                        name="email"
                        placeholder="Ingresar mail"
                        onChange={(e) => {handleChange(e)}}
                    />
                </div> */}
                        <MyInput l='Email' t='text' v={props.email} n='email'/>
                <div className={styles.div_input}>
                    <label>Contraseña:</label>
                    <input 
                        type="password" 
                        value={input.password}
                        name="password"
                        placeholder="Ingresar contraseña actual"
                        onChange={(e) => {handleChange(e)}}
                    />
                   
                </div>
                <div className={styles.div_input}>
                    <label>Nueva Contraseña:</label>
                    <input 
                        type="password" 
                        value={input.newPassword}
                        name="newPassword"
                        placeholder="Ingresar nueva contraseña"
                        onChange={(e) => {handleChange(e)}}
                    />
                    
                </div>
                <button className={styles.btn} type="submit">
                Listo
                </button>
            </form>
        </div>
    ) : '';
}