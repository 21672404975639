import React, { useState, useEffect } from "react";
// import { FaSistrix, FaRedo } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders } from "../../../../redux/actions";
// import SearchBar from '../SearchBar/SearchBar';
import style from "./AdminShipping.module.css";
// import info from "../../../../assets/info.png";
import InfoPedido from "../../../PanelDeBlikz/InfoPedido";

export default function AdminShipping(props) {
  const allOrders = useSelector((state) => state.allOrders);
  // const reload = useSelector((state) => state.reload);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrders(props.id));
    // dispatch(refreshOneTime(true))
  }, [dispatch, props.id]);

  //   const [render, setRender] = React.useState(orders);
  // const [search, setSearch] = useState("");
  const [btnChange, setBtnChange] = useState(false);
  const [idN, setIdN] = useState("");

  // if(reload === true){
  //   window.location.reload()
  //   dispatch(refreshOneTime())
  // }

  // console.log('render!!!',render);
  //   const orderByDate = (value) => {
  //     let order = orders.sort((a, b) => {
  //       return (
  //         Number(b.order.substring(1, 10)) - Number(a.order.substring(1, 10))
  //       );
  //     });
  //     if (value === "Descendent") {
  //       setRender((prev) => [...order]);
  //     } else setRender([...order.reverse()]);
  //   };
  //   const searchHandler = (value) => {
  //     if (search.length) {
  //       setRender((prev) =>
  //         orders.filter(
  //           (e) =>
  //             //e.products.toUpperCase().includes(value.toUpperCase()) ||
  //             e.id === value
  //             // e.clientId === value
  //         )
  //       );
  //     } else alert("Search field empty");
  //   };
  //   const refreshHandler = () => {
  //     setRender(orders);
  //     setSearch("");
  //   };
  //jj

  const idOpen = (id) => {
    setBtnChange(true);
    setIdN(id);
  };

  return (
    <div className={style.container}>
      <div className={style.filters}>
        <div className={style.selects}>
          <select
            name="byDate"
            id=""
            //   onChange={(e) => orderByDate(e.target.value)}
          >
            <option>Ordenar</option>
            <option value="Ascendent">Ascendente</option>
            <option value="Descendent">Descendente</option>
          </select>

          <select
            name="byDate"
            id=""
            //   onChange={(e) => orderByDate(e.target.value)}
          >
            <option>Estado</option>
            <option value="Descendent">Entregados</option>
            <option value="Ascendent">Pendientes</option>
          </select>

          {/* <select
              name="byDate"
              id=""
            //   onChange={(e) => orderByDate(e.target.value)}
            >
              <option>Filtar por Fecha</option>
              <option value="Descendent">Descendente por Orden #</option>
              <option value="Ascendent">Ascendent by Order #</option>
            </select> */}
        </div>
      </div>

      {/* <div className={style.table}> */}
      <InfoPedido
        trigger={btnChange}
        setTrigger={setBtnChange}
        idOrder={idN}
        // cancelado={e.cancelado}
        // orderDetail={orderDetail}
      ></InfoPedido>
      {/* <div className={style.tabletop}> */}
      <table>
        <thead>
          <tr>
            <th className={style.topRowLeft}>N°</th>
            <th>Fecha</th>
            {/* <th>Productos</th> */}
            <th>Cliente</th>
            <th className={style.status}>Estado</th>
            {/* <th className={style.topRowRight}><img src={info} alt="info" height="22px"/></th> */}
          </tr>
        </thead>
        {/* </div> */}
        {/* </colgroup> */}
        {/* </div> */}
        {allOrders[0] ? (
          allOrders[0][0] ? (
            allOrders[0].map((e, index) => (
              // <div>
              <tbody key={index}>
                <tr onClick={() => idOpen(e.id)}>
                  <td>
                    <b># {e.id}</b>
                  </td>
                  <td>{e.createdAt.slice(0, 10)}</td>
                  <td>{e.cliente}</td>
                  {e.cancelado === true ? (
                    <td>Cancelado</td>
                  ) : (
                    <td>{e.status}</td>
                  )}
                  {/* <td><button onClick={() => idOpen(e.id)}>Ver más</button></td> */}
                </tr>
              </tbody>
              // </div>
            ))
          ) : (
            <h1 className={style.textNotProducts}>No hay envíos realizados</h1>
          )
        ) : (
          ""
        )}
      </table>
      {/* </div> */}
    </div>
  );
}
