import React,{useState} from 'react';
import styles from './MobileMenu.module.css';
import home from '../../../../assets/Dashboard/IconosNavbar/ecommerce.png';
import config from '../../../../assets/Dashboard/IconosNavbar/configuraciones.png';
import cerrar from '../../../../assets/Dashboard/IconosNavbar/logout.png';
import LogOut from "../../asideDashboard/LogOut/LogOut";

const MobileMenu = ({ setPanelActive, panelActive }) => {
    const [activateConfirm, setActivateConfirm] = useState(false);
    const clickHandler = (e) => {
        console.log(`${e}`);
        setPanelActive({ [e]: true });
      };

      const logOut = () => {
        setActivateConfirm((prev) => !prev);
        console.log(activateConfirm);
      };

  return (
     <div className={styles.gral}>
        <div>
        {activateConfirm && <LogOut setActivateConfirm={setActivateConfirm} />}
        <button 
        className={`${styles.boton1} ${panelActive.home && styles.active}`}
         type="submit"
         id="home"
         onClick={(e) => clickHandler("home")}
        ><img src={home} alt="Inicio" height="30px"/></button>
        </div>
        <div className={styles.div_center}>
        <button 
         className={`${styles.boton1} ${panelActive.account && styles.active}`}
         type="submit"
         id="account"
         onClick={(e) => clickHandler("account")}
        ><img src={config} alt="Cuenta" height="30px"/></button>
        </div>
        <div>
        <button 
         className={`${styles.boton1} ${panelActive.account && styles.active}`}
         type="submit"
         onClick={logOut}
        ><img src={cerrar} alt="cerrarSesión" height="30px"/></button>
        </div>
        </div>
  )
}

export default MobileMenu