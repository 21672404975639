import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetail } from "../../redux/actions";
// import SliderSeguimiento from "../LandingNegocio/SliderSeguimiento/SliderSeguimiento";
import CancelarPedido from "./CancelarPedido/CancelarPedido";
import styles from "./InfoPedido.module.css";
// import { updateUser } from "../../../redux/actions";

// import MyInput from "../../../basics/myInput";

export default function InfoPedido(props) {
  const dispatch = useDispatch();
  const orderDetail = useSelector((state) => state.orderDetail);
  const [btnChangeCancelar, setBtnChangeCancelar] = useState(false);

  useEffect(() => {
    dispatch(getOrderDetail(props.idOrder));
  }, [dispatch, props.idOrder]);

  // const cancell = () =>{
  // dispatch(cancellOrder(props.idOrder, true));
  // props.setTrigger(false);
  // }

  return props.trigger ? (
    <div className={styles.popup}>
      {props.children}
      <CancelarPedido
        trigger={btnChangeCancelar}
        setTrigger={setBtnChangeCancelar}
        setCloseAll={props.setTrigger}
        idOrder={props.idOrder}
      />
      <div className={styles.gral}>
        <div className={styles.divTitle}>
          <h1>Información del pedido</h1>
          <button
            className={styles.close}
            onClick={() => props.setTrigger(false)}
          >
            x
          </button>
        </div>

        {/* <MyInput l='Email' t='text' v={input.email} n='email' p='Enter email' o={handleChange}/> */}
        {orderDetail[0]
          ? orderDetail.map((el) => (
              <div className={styles.container} key={el.id}>
                <div className={styles.col1}>
                  <div className={styles.datos}>
                    <h3>Nro de orden:</h3>
                    <h4>#{el.id}</h4>
                  </div>
                  <div className={styles.datos}>
                    <h3>Dirección de retiro:</h3>
                    <h4>{el.direccionRetiro}</h4>
                  </div>
                  {el.pisoDptoRetiro ? (
                    <div className={styles.datos}>
                      <h3>Número de casa</h3>
                      <h4>{el.pisoDptoRetiro}</h4>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={styles.datos}>
                    <h3>Teléfono retiro:</h3>
                    <h4>{el.telefonoNegocio}</h4>
                  </div>
                  <div className={styles.datos}>
                    <h3>Dirección de entrega:</h3>
                    <h4>{el.direccionEntrega}</h4>
                  </div>
                  {el.pisoDptoEntrega ? (
                    <div className={styles.datos}>
                      <h3>Número de casa</h3>
                      <h4>{el.pisoDptoEntrega}</h4>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={styles.datos}>
                    <h3>Nombre del cliente:</h3>
                    <h4>{el.nombreCliente}</h4>
                  </div>
                  {/* </div>
                <div className={styles.col2}> */}
                  <div className={styles.datos}>
                    <h3>Teléfono del cliente:</h3>
                    <h4>{el.telefonoCliente}</h4>
                  </div>
                  <div className={styles.datos}>
                    <h3>Detalles extra:</h3>
                    <h4>{el.detallesExtra}</h4>
                  </div>
                  <div className={styles.datos}>
                    <h3>Cobrar envío en:</h3>
                    <h4>{el.quienAbona}</h4>
                  </div>
                  <div className={styles.datos}>
                    <h3>Costo de envío:</h3>
                    <h4>${el.costoEnvio}</h4>
                  </div>
                  <div className={styles.datos}>
                    <h3>Fecha:</h3>
                    <h4>{el.time}</h4>
                  </div>
                  <div className={styles.datos}>
                    <h3>Horario seleccionado:</h3>
                    <h4>{el.horarioEnvio}</h4>
                  </div>
                  {/* {!el.pagado ? (
                    <div className={styles.datos}>
                      <h3>Pagar producto:</h3>
                      {!el.precioProducto &&
                      <h4>pagado</h4>
                     }
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            ))
          : ""}
        {orderDetail[0] && orderDetail[0].cancelado !== "cancelado" ? (
          <button
            className={styles.cancelar}
            onClick={() => setBtnChangeCancelar(true)}
          >
            Cancelar pedido
          </button>
        ) : (
          <button className={styles.cancelado}>
            Este pedido fue cancelado
          </button>
        )}
      </div>
    </div>
  ) : (
    ""
  );
}
