import React from 'react';
import styles from './Message.module.css';
// import ws from "../../../../assets/ws.png";

const Message = () => {
  return (
    <div className={styles.gral}>
        <h2>Te damos la bienvenida a tu plataforma de envíos.</h2>
        {/* <a href="https://wa.me/+529613693332" target="_blank" without rel="noreferrer"><img className={styles.ws} src={ws} alt="Whatsapp" height="47px"/></a> */}
    </div>
  )
}

export default Message;