import React, { useState} from "react";
import styles from "./asideDashboard.module.css";
import Logo from "../../../assets/blikz1.png";
import iconHome from "../../../assets/Dashboard/IconosNavbar/ecommerce.png";
import iconConfig from "../../../assets/Dashboard/IconosNavbar/configuraciones.png";
// import { Navigate, useNavigate } from "react-router";

import LogOut from "./LogOut/LogOut";


export default function Aside({ setPanelActive, panelActive }) {
  const [activateConfirm, setActivateConfirm] = useState(false);

  const clickHandler = (e) => {
    setPanelActive({ [e]: true });
  };

  const logOut = () => {
    setActivateConfirm((prev) => !prev);
  };

  return (
    <div className={styles.gral}>
    <div className={styles.box}>
      {activateConfirm && <LogOut setActivateConfirm={setActivateConfirm} />}
      <ul>
        <li>
          <img className={styles.logo} src={Logo} alt="img"/>
        </li>
        {/* BOTÓN INICIO */}
        <li>
          <button
            className={`${styles.btn1} ${panelActive.home && styles.active}`}
            type="submit"
            id="home"
            onClick={(e) => clickHandler(e.target.id)}
          >
            <img className={styles.iconH} src={iconHome} alt="icon" />
            <span>Inicio</span>{" "}
          </button>
        </li>
        {/* BOTÓN MIS ENVÍOS */}
        {/* <li>
          <button
            className={`${styles.btn} ${
              panelActive.customers && styles.active
            }`}
            type="submit"
            id="customers"
            onClick={(e) => clickHandler(e.target.id)}
          >
            <img className={styles.iconCu} src={iconMapa} alt="icon" />
            Mis envíos
          </button>
        </li> */}
        {/* BOTÓN CONFIGURACIÓN */}
        <li>
          <button
            className={`${styles.btn} ${panelActive.account && styles.active}`}
            type="submit"
            id="account"
            onClick={(e) => clickHandler(e.target.id)}
          >
           <img className={styles.iconAcc} src={iconConfig} alt="icon" />
            Configuración
          </button>
        </li>
       {/* BOTÓN CERRAR SESIÓN */}
      </ul>
      <button className={styles.btn2} type="submit" onClick={logOut}>
        Cerrar sesión
      </button>
    </div>
    </div>
  );
}
