import React from "react";
import styles from "./ShopInfo.module.css";
import tiendaDefecto from "../../../../assets/tienda.png";

const ShopInfo = (props) => {
  return (
    <div className={styles.gral}>
      <div className={styles.logo}>
        <img src={tiendaDefecto} alt="logoTienda" />
      </div>
      <div className={styles.texto}>
        <h4>Hola,</h4>
        <h1>{props.negocio}</h1>
      </div>
    </div>
  );
};

export default ShopInfo;
