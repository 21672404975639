import React, { useState, useEffect, useRef } from "react";
import styles from "./InputsDireccion.module.css";
import puntos from "../../../../assets/Map/puntos.png";
import { useDispatch, useSelector } from "react-redux";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import {
  SET_LUGAR_ENTREGA,
  SET_LUGAR_RETIRO,
  SET_PUERTA_ENTREGA,
  SET_PUERTA_RETIRO,
  SET_TRIP_INFO,
  getUserInfo,
} from "../../../../redux/actions";

const InputsDireccion = () => {
  const dispatch = useDispatch();
  const lugarRetiro = useSelector((state) => state.lugarRetiro);
  const lugarEntrega = useSelector((state) => state.lugarEntrega);
  const puertaEntregaState = useSelector((state) => state.puertaEntrega);
  const puertaRetiroState = useSelector((state) => state.puertaRetiro);
  const userInfo = useSelector((state) => state.userInfo);
  const [inputRetiro, setInputRetiro] = useState("");
  const [inputEntrega, setInputEntrega] = useState("");
  const [puertaRetiro, setPuertaRetiro] = useState("");
  const [puertaEntrega, setPuertaEntrega] = useState("");
  const [inputOpen, setInputOpen] = useState(false);
  const originRef = useRef();
  const destinationRef = useRef();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAigElg1D8N6TMP0LZla2CP9yJ0HxCT6bY",
    libraries: ["places"],
  });

  useEffect(() => {
    if (originRef.current && destinationRef.current) {
      dispatch({
        type: SET_LUGAR_ENTREGA,
        payload: inputEntrega,
      });
      dispatch({
        type: SET_LUGAR_RETIRO,
        payload: inputRetiro,
      });
      dispatch({
        type: SET_PUERTA_ENTREGA,
        payload: puertaEntrega,
      });
      dispatch({
        type: SET_PUERTA_RETIRO,
        payload: puertaRetiro,
      });
    }
  }, [
    lugarRetiro,
    lugarEntrega,
    inputEntrega,
    inputRetiro,
    puertaEntrega,
    puertaRetiro,
    puertaEntregaState,
    puertaRetiroState,
    dispatch,
  ]);

  useEffect(() => {
    if (userInfo.length < 1) {
      const email = JSON.parse(localStorage.getItem("Mail-Usuario-Logueado"));
      dispatch(getUserInfo(email));
    }
  }, [dispatch, userInfo]);

  function handleSelect(e) {
    if (e.target.value.length > 1) {
      if (e.target.value === "Negocio") {
        setInputRetiro(userInfo.direccion);
        setInputOpen(false);
      }
      if (e.target.value !== "Negocio") {
        setInputOpen(true);
        setInputRetiro("");
      }
    }
  }

  useEffect(() => {
    if (
      originRef.current &&
      destinationRef.current &&
      originRef.current.value.length > 5 &&
      destinationRef.current.value.length > 5 &&
      inputRetiro.length > 0 &&
      inputEntrega.length > 0
    ) {
      setTimeout(() => {
        calculateRoute();
      }, 1000);
    } else {
      dispatch({
        type: SET_TRIP_INFO,
        payload: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRetiro, inputEntrega, originRef, destinationRef]);

  function handlePlaceChanged(autocomplete, type) {
    const selectedPlace = autocomplete.getPlace();
    if (selectedPlace && selectedPlace.formatted_address) {
      if (type === "retiro") {
        setInputRetiro(selectedPlace.formatted_address);
      } else {
        setInputEntrega(selectedPlace.formatted_address);
      }
    }
  }

  function handleAutocompleteLoad(autocomplete, type) {
    autocomplete.addListener("place_changed", () =>
      handlePlaceChanged(autocomplete, type)
    );
  }
  async function calculateRoute() {
    if (originRef.current.value === "" || destinationRef.current.value === "") {
      return;
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: inputRetiro,
      destination: inputEntrega,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    if (results) {
      dispatch({
        type: SET_TRIP_INFO,
        payload: {
          distance: Math.round(
            parseFloat(
              results.routes[0].legs[0].distance.text.replace(",", ".")
            )
          ),
          duration: results.routes[0].legs[0].duration.text,
        },
      });
      // }
    }
  }

  if (!isLoaded) {
    return <h1> </h1>;
  }
  return (
    <>
      <div className={styles.infoDiv}>
        <img src={puntos} alt="" height="120px" />
        <div className={styles.inputsDiv}>
          <div className={styles.inputDiv}>
            <select
              onChange={(ev) => handleSelect(ev)}
              className={styles.input}
            >
              <option value="">
                Direccion de retiro *
                {/* {e.repartidor ? e.repartidor : "Direccion de retiro *"} */}
              </option>
              <option value="Negocio">Mi negocio</option>
              <option value="Nueva">Nueva dirección</option>
            </select>
            {(inputOpen || inputRetiro.length > 0) && (
              <Autocomplete
                onLoad={(autocomplete) =>
                  handleAutocompleteLoad(autocomplete, "retiro")
                }
              >
                <input
                  type="text"
                  name="direccionRetiro"
                  value={inputRetiro}
                  placeholder="Dirección de retiro *"
                  onChange={(e) => {
                    setInputRetiro(e.target.value);
                  }}
                  className={styles.input}
                  ref={originRef}
                />
              </Autocomplete>
            )}
          </div>
          {inputRetiro.length > 0 && (
            <>
              <div className={styles.inputLine}></div>
              <input
                type="text"
                name="puertaRetiro"
                value={puertaRetiro}
                placeholder="Número de puerta (opcional)"
                className={styles.inputPuerta}
                onChange={(e) => {
                  setPuertaRetiro(e.target.value);
                }}
              />
            </>
          )}
          <div className={styles.inputDiv}>
            <Autocomplete
              onLoad={(autocomplete) =>
                handleAutocompleteLoad(autocomplete, "entrega")
              }
            >
              <input
                type="text"
                name="direccionEntrega"
                value={inputEntrega}
                placeholder="Dirección de entrega *"
                onChange={(e) => {
                  setInputEntrega(e.target.value);
                }}
                className={styles.input}
                ref={destinationRef}
              />
            </Autocomplete>
          </div>
          {inputEntrega.length > 0 && (
            <>
              <div className={styles.inputLine}></div>
              <input
                type="text"
                name="puertaEntrega"
                value={puertaEntrega}
                placeholder="Número de puerta (opcional)"
                className={styles.inputPuerta}
                onChange={(e) => {
                  setPuertaEntrega(e.target.value);
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InputsDireccion;
