import React from "react";
// import { useNavigate } from "react-router";
import { deleteToken } from "../../../Login/controllers/tokenFunctions";
import style from "./LogOut.module.css";
import logo from "../../../../assets/logo.png";

export default function Confirm(props) {
  const cancelHandler = () => {
    props.setActivateConfirm((prev) => !prev);
  };
  const acceptHandler = () => {
    deleteToken();
    localStorage.removeItem("Mail-Usuario-Logueado");
    window.location.replace("/");
    // navigate("/admin")
  };
  return (
    <div className={style.container}>
      <div className={style.confirm_box}>
        <img src={logo} alt="Blikz" height="45px"/>
        <h3>¿Cerrar Sesión?</h3>
        <div className={style.btns_div}>
          <button onClick={acceptHandler}>Aceptar</button>
          <button onClick={cancelHandler}>Cancelar</button>
        </div>
      </div>
    </div>
  );
}
