import React from "react";
import styles from "./Account.module.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChangePassword from "./ChangePassword/ChangePassword";
import ChangeEmail from "./ChangeEmail/ChangeEmail";
import ChangeAddress from "./ChangeAddress/ChangeAddress";
import ChangePhone from "./ChangePhone/ChangePhone";
import { getUserInfo } from "../../../redux/actions";
// import cajatexto from '../../../assets/cuadrotexto.png';
// import trashcan from '../../../assets/trashcan.png';
import mail from "../../../assets/email.png";
import llave from "../../../assets/llave.png";
import direccion from "../../../assets/direccion.png";
import telefono from "../../../assets/telefono.png";
// import tiendaDefecto from '../../../assets/tienda.png';

export default function Account() {
  const dispatch = useDispatch();
  // const allUsers1 = [{id:"1", img: logoTienda, negocio:"Frauen",direccion:"9 de Julio 1468", mail:"equipofrauen@gmail.com",telefono:"3512843532"}]
  // useSelector((state) => state.allUsers);

  const [btnChangeP, setBtnChangeP] = useState(false);
  const [btnChangeE, setBtnChangeE] = useState(false);
  const [btnChangeA, setBtnChangeA] = useState(false);
  const [btnChangePh, setBtnChangePh] = useState(false);

  // const [btnAddUser, setBtnAddUser] = useState(false);
  // const [show, setShow] = useState(false);
  const userData = useSelector((state) => state.userInfo);

  // function AdvertenciaDireccionOn(e){
  //   setShow(true)
  // }

  // function AdvertenciaDireccionOff(e){
  //   setShow(false)
  // }

  useEffect(() => {
    if (userData.length < 1) {
      const email = JSON.parse(localStorage.getItem("Mail-Usuario-Logueado"));
      dispatch(getUserInfo(email));
    }
  }, [dispatch, userData]);

  //console.log(allUsers);

  // function handleDelete(e) {
  //   dispatch(deleteUser(e.target.value));
  // }
  // console.log(userData, "heuuu")

  return (
    <div className={styles.page}>
      {/* {show ? */}
      {/* <div className={styles.cuadrotexto}>
        <h3>Esta es tu dirección de retiro</h3>
      <img src={cajatexto} alt="Proximamente" height="75px" width="225px"/>
      </div> */}
      {/* :""} */}
      <ChangePassword
        trigger={btnChangeP}
        setTrigger={setBtnChangeP}
        id={userData.id}
        email={userData.email}
      ></ChangePassword>
      <ChangeEmail
        trigger={btnChangeE}
        setTrigger={setBtnChangeE}
        email={userData.email}
        id={userData.id}
        // users = {allUsers1}
      ></ChangeEmail>
      <ChangeAddress
        trigger={btnChangeA}
        setTrigger={setBtnChangeA}
        email={userData.email}
        id={userData.id}
      ></ChangeAddress>
      <ChangePhone
        trigger={btnChangePh}
        setTrigger={setBtnChangePh}
        email={userData.email}
        id={userData.id}
      ></ChangePhone>
      <h2>Datos del usuario</h2>
      <div className={styles.box}>
        <button
          onClick={() => setBtnChangeE(true)}
          className={styles.button}
          type="submit"
        >
          <img src={mail} alt="Mail" height="40px" />
          <h3>Mail</h3>
        </button>
        <button
          onClick={() => setBtnChangeP(true)}
          className={styles.button}
          type="submit"
        >
          <img src={llave} alt="Contraseña" height="40px" />
          <h3>Contraseña</h3>
        </button>
        <button
          onClick={() => setBtnChangeA(true)}
          className={styles.button}
          type="submit"
        >
          <img src={direccion} alt="Dirección" height="40px" />
          <h3>Dirección</h3>
        </button>
        <button
          onClick={() => setBtnChangePh(true)}
          className={styles.button}
          type="submit"
        >
          <img src={telefono} alt="Teléfono" height="40px" />
          <h3>Teléfono</h3>
        </button>
      </div>
      <h2>Información de tu cuenta</h2>
      <div className={styles.details}>
        <h2>{userData.telefono}</h2>
        <h2>{userData.email}</h2>
        <h2>{userData.direccion}</h2>
      </div>
    </div>
  );
}
