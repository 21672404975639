import React, { useRef } from "react";
import styles from "./TopBanner.module.css";
import blikz from "../../assets/blikz1.png";

const TopBanner = () => {
  const carousel = useRef(null);

  const openToWork = [
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
    <img src={blikz} alt="logo" height="18px" />,
  ];

  return (
    <a href="http://blikz.webflow.io/" target="_blank"  rel="noreferrer">
      <div className={styles.gral}>
        <div className={styles.container} ref={carousel}>
          <div className={styles.icons}>
            {openToWork?.map((c, index) => {
              return <h3 key={index}>{c}</h3>;
            })}
          </div>
        </div>
      </div>
    </a>
  );
};

export default TopBanner;
