import React, {useEffect} from 'react';
import styles from './Home.module.css';
// import {Header, Landing, Funcionamiento, Servicios, Beneficios, Footer} from "../ComponentsLanding";
// import {Link} from "react-router-dom";
// import Monthly from "./Monthly";
// import Anual from "./Anual";


const Home = () => {

  // const [panelActive, setPanelActive] = React.useState({
  //   mensual: true,
  //   anual: false,
  // });
  useEffect(() => {
   window.location.replace("/#/admin")
  }, [])
  

  return (
    <div className={styles.gral}>
        {/* <div>
        <Header/>
        </div>
        <div>
          <Landing/>
        </div>
        <div id="como-funciona">
          <Funcionamiento/>
        </div>
        <div id="servicios">
          <Servicios/>
        </div>
        <div id="beneficios">
          <Beneficios/>
        </div> */}
       {/* <div id="planes" className={styles.div_Navbar}>
       <Planes setPanelActive={setPanelActive} panelActive={panelActive}/>
      <section>
          {panelActive.mensual &&  <Monthly/>}
          {panelActive.anual && <Monthly/>}
          {!panelActive.mensual &&  !panelActive.anual &&  <Monthly/>}
        </section>
      </div>*/}
      {/* <div className={styles.button_div}> 
      <Link to="/registrarme"> 
      <button>Registrate</button>
      </Link>
      </div> */}
      {/* <div> */}
        {/* <Footer/> */}
      {/* </div> */}

    </div>
  )
}

export default Home