import React, { useState, useEffect } from "react";
import styles from "./Seguimiento.module.css";
// import reloj from "../../../assets/LandingNegocio/reloj.png";
import error404 from "../../../assets/LandingNegocio/error404.png";
import pedidoEntregado from "../../../assets/LandingNegocio/pedidoEntregado.png";
import pedidoCancelado from "../../../assets/LandingNegocio/cancelar.png";
import CancelarPedido from "../../PanelDeBlikz/CancelarPedido/CancelarPedido";
import TopBanner from "../TopBanner";
import { createGlobalStyle } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, getOrderDetail } from "../../../redux/actions";
import { useParams } from "react-router";
import ProgressBar from "./ProgressBar/ProgressBar";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #F4F4F4; /* Cambia el color de fondo deseado */
  }
`;

const Seguimiento = () => {
  const dispatch = useDispatch();
  // var contadorMinutos = 1;
  // var contadorSegundos = 10;
  let { nroOrden } = useParams();
  // const [segundos, setSegundos] = useState(contadorSegundos);
  // const [minutos, setMinutos] = useState(contadorMinutos);
  const allUsers = useSelector((state) => state.allUsers);
  const orderDetail = useSelector((state) => state.orderDetail[0]);
  const [btnChangeCancelar, setBtnChangeCancelar] = useState(false);
  const [idLanding, setIdLanding] = useState("");
  const [status, setStatus] = useState("");
  // const nombreNegocio = allUsers.find((el) => el.id === idLanding);
  // const refresh = useSelector((state) => state.refreshState)

  // const allOrdersFilteredAll = [JSON.parse(localStorage.getItem("envios"))]
  // const allOrdersFiltered = allOrdersFilteredAll.filter(el => el.status !== "Finalizado")
  // const idArray = allOrdersFiltered[0].map(el => el.id)
  // const correctURL = idArray.includes(parseInt(nroOrden))
  // const currentOrder = allOrders[0].find(el => el.id === parseInt(nroOrden)).status

  // const idLanding = useSelector((state) => state.idLanding);

  // const timer = () => {
  //   // setMinutos(minutos-1)
  //   // if(minutos < 7){
  //   // setMinutos(contadorMinutos);
  //   if (contadorMinutos === 0) {
  //     clearInterval(timer);
  //   }
  //   // contadorMinutos= contadorMinutos - 1;
  //   // setSegundos(59);
  //   contadorSegundos = 59;
  //   // if (segundos !== 0) {
  //   setInterval(() => {
  //     // setSegundos(contador)
  //     // console.log(number);
  //     //   if (segundos !== 0) {
  //     if (contadorMinutos === 0 && contadorSegundos === 0) {
  //       setMinutos("hola");
  //       setSegundos("hola");
  //     } else {
  //       setSegundos(
  //         contadorSegundos > 0 && contadorMinutos > -1
  //           ? contadorSegundos--
  //           : (contadorSegundos = 59)
  //       );
  //     }
  //     contadorSegundos === 0 &&
  //       contadorMinutos !== 0 &&
  //       setMinutos((contadorMinutos = contadorMinutos - 1));
  //     // }
  //     // if(contadorSegundos < 1){
  //     //   setMinutos(minutos-1)
  //     //   setSegundos(59)
  //     // }
  //     // contadorSegundos = segundos
  //     // setMinutos(minutos === minutos-1)

  //     // if(segundos == 0 && minutos < 7){
  //     //     setMinutos(minutos-1)
  //     //     setSegundos(59)
  //     // }
  //   }, 1000);
  //   // } else {
  //   //   contadorMinutos = contadorMinutos - 1;
  //   //   contadorSegundos = 59;
  //   // }
  //   // }
  //   // if
  //   return segundos && minutos;
  // };

  useEffect(() => {
    if (!idLanding) {
      setIdLanding(JSON.parse(localStorage.getItem("idLanding")));
    }
  }, [idLanding]);

  useEffect(() => {
    if (!allUsers[0]) {
      dispatch(getAllUsers());
    }
  }, [allUsers, dispatch]);

  useEffect(() => {
    if (!orderDetail) {
      dispatch(getOrderDetail(parseInt(nroOrden)));
    }
    if (orderDetail !== undefined && orderDetail.cancelado === null) {
      setStatus(orderDetail.status);
    }
    if (orderDetail && orderDetail.cancelado === "cancelado") {
      setStatus("Cancelado");
    }
  }, [orderDetail, nroOrden, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 60000);
  }, [orderDetail]);

  return (
    <>
      <GlobalStyle />
      <CancelarPedido
        trigger={btnChangeCancelar}
        setTrigger={setBtnChangeCancelar}
        setCloseAll={setBtnChangeCancelar}
        idOrder={nroOrden}
      />
      <div className={styles.topBanner}>
        <TopBanner />
      </div>
      {status && nroOrden !== "undefined" ? (
        <>
          {status === "Entregado" ? (
            <>
              <div className={styles.pedidoEntregado}>
                <img src={pedidoEntregado} alt="" />
                <h3 className={styles.mensaje}>
                  ¡Tu pedido ha sido entregado con éxito!
                </h3>
                <button
                className={styles.buttonNuevoP}
                onClick={() =>
                  // timer();
                  // setMinutos(minutos - 1);
                  // setSegundos(59);
                  // contadorMinutos = contadorMinutos - 1
                  window.location.replace(`/#/admin`)
                }
              >
                Nuevo pedido
              </button>
              </div>
            </>
          ) : status === "Cancelado" ? (
               <>
                <div className={styles.pedidoCancelado}>
                  <img src={pedidoCancelado} alt="Pedido Cancelado" />
                  <h3 className={styles.mensaje}>
                    ¡Tu pedido ha sido cancelado!
                  </h3>   
                  <button
                className={styles.buttonNuevoP}
                onClick={() =>
                  // timer();
                  // setMinutos(minutos - 1);
                  // setSegundos(59);
                  // contadorMinutos = contadorMinutos - 1
                  window.location.replace(`/#/admin`)
                }
              >
                Nuevo pedido
              </button>
                </div>
              </>
          ):(
            <>
              <div className={styles.gifDivGral}>
                <ProgressBar
                  caseType={
                    status === "Buscando Repartidor"
                      ? 1
                      : status === "Repartidor en camino"
                      ? 2
                      : 3
                  }
                />
              </div>
              {status === "Buscando Repartidor" ? (
                <h3 className={styles.mensaje}>Buscando repartidor...</h3>
              ) : (
                ""
              )}
              {status === "Repartidor en camino" ? (
                <h3 className={styles.mensaje}>
                  Acabamos de asignarte un repartidor y está en camino a la
                  dirección de retiro.
                </h3>
              ) : (
                ""
              )}
              {status === "Retirado" ? (
                <h3 className={styles.mensaje}>
                  El repartidor ya retiró tu pedido y está en camino a la
                  dirección de entrega.
                </h3>
              ) : (
                ""
              )}
              <div className={styles.cardInformation}>
                {/* <h3 className={styles.timeText}>Tiempo estimado de entrega:</h3> */}
                {/* <div className={styles.time}>
                     <img src={reloj} alt="reloj" height="20px" />
                     <h3 id="temporizador">
                       {minutos.length > 1? minutos:"0"+ minutos}:{segundos > 9? segundos:"0"+segundos}
                  </h3>
                   </div> */}
                {/* <h4 className={styles.costoEnvio}>Costo de envío:</h4>
                <h4 className={styles.precio}>${orderDetail.costoEnvio}</h4> */}
                <h4 className={styles.direccionEntrega}>
                  Dirección de entrega:
                </h4>
                <h4 className={styles.direccionEntregaInfo}>
                  {orderDetail.direccionEntrega.split("-")[0]}
                </h4>
              </div>
              <div className={styles.divButtons}>
                <button
                  className={styles.buttonCancelar}
                  onClick={() => setBtnChangeCancelar(true)}
                >
                  Cancelar
                </button>
                {/* <button
                className={styles.buttonNuevoP}
                onClick={() =>
                  // timer();
                  // setMinutos(minutos - 1);
                  // setSegundos(59);
                  // contadorMinutos = contadorMinutos - 1
                  window.location.replace(`/#/${nombreNegocio.usuario}`)
                }
              >
                Nuevo pedido
              </button> */}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className={styles.gralError}>
            <img src={error404} alt="ERORR 404" height="220px" />
            <h3>
              No encontramos ningún pedido en curso con este número de orden.
            </h3>
          </div>
          {/* <button
            className={styles.buttonNuevoP}
            onClick={() =>
              window.location.replace(`/#/${nombreNegocio.usuario}`)
            }
          >
            Nuevo pedido
          </button> */}
        </>
      )}
    </>
  );
};

export default Seguimiento;
