import React, { useState } from "react";
import styles from "./OrdersBox.module.css";
import upArrow from "../../../assets/LandingNegocio/up-arrow.png";
import downArrow from "../../../assets/LandingNegocio/down-arrow.png";
import SliderSeguimiento from "../SliderSeguimiento/SliderSeguimiento";

const OrdersBox = () => {
  const [openSolapa, setOpenSolapa] = useState(false);
  return (
    <div className={styles.gral}>
      <div
        className={styles.arrow}
        onClick={() =>
          !openSolapa ? setOpenSolapa(true) : setOpenSolapa(false)
        }
      >
        {!openSolapa ? (
          <img src={upArrow} alt="up" height="15px" />
        ) : (
          <img src={downArrow} alt="up" height="15px" />
        )}
      </div>
      <div
        className={!openSolapa ? styles.box : styles.open}
        // onClick={() =>
        //   !openSolapa ? setOpenSolapa(true) : setOpenSolapa(false)
        // }
      >
        {!openSolapa ? "Pedidos en curso" : <SliderSeguimiento/>}
      </div>
    </div>
  );
};

export default OrdersBox;
