import React from "react";
import styles from "./SliderNegocioFinalizado.module.css";
import Cards from "./CardsNegocioFinalizado/CardsNegocioFinalizado";
import { useRef } from "react";

const SliderNegocioFinalizado = () => {
  const carousel = useRef(null);

  return (
    <>
      <div className={styles.gral}>
        <div className={styles.container}>
          <div className={styles.carousel} ref={carousel}>
            <Cards />
          </div>
        </div>
      </div>
    </>
  );
};

export default SliderNegocioFinalizado;
