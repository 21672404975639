import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./CardsNegocioCurso.module.css";
import { getAllOrders } from "../../../../../redux/actions";
import { Link } from "react-router-dom";

export default function CardsNegocioCurso() {
  const dispatch = useDispatch();
  const idLanding = JSON.parse(localStorage.getItem("idLanding"));
  const allOrders = useSelector((state) => state.allOrders);

  useEffect(() => {
    if (!allOrders[0]) {
      dispatch(getAllOrders(idLanding));
    }
  }, [allOrders, dispatch, idLanding]);

  const allOrdersFiltered = allOrders[0]
    ? allOrders[0].filter(
        (el) => el.status !== "Cancelado" && el.status !== "Entregado"
      )
    : undefined;

  return (
    <>
      {allOrders[0] && allOrdersFiltered[0] ? (
        allOrdersFiltered.map((el) => (
          <Link
            className={styles.cart}
            to={"/seguimiento/" + el.id}
            key={el.id}
          >
            {el.id !== null && (
              <div className={styles.Card} key={el.id}>
                <h3 className={styles.name}>#Orden {el.id}</h3>
              </div>
            )}
          </Link>
        ))
      ) : (
        <h3 className={styles.sinPedidos}>No hay pedidos en curso</h3>
      )}
    </>
  );
}
