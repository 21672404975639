import React, { useState, useEffect } from "react";
import { getUserLocation } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./NuevoEnvio.module.css";
import InputsDireccion from "./InputsDireccion/InputsDireccion";
import {
  SET_USER_LOCATION,
  createNewOrder,
  getAllOrders,
  getAllUsers,
  getIpClient,
  // cancellOrder,
  getUserInfo,
} from "../../../redux/actions";
import pedidoCreado from "../../../assets/pedidoCreado.gif";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #F4F4F4; /* Cambia el color de fondo deseado */
  }
`;

const NuevoEnvio = () => {
  const dispatch = useDispatch();
  const direccionRetiro = useSelector((state) => state.lugarRetiro);
  const direccionEntrega = useSelector((state) => state.lugarEntrega);
  const puertaEntrega = useSelector((state) => state.puertaEntrega);
  const puertaRetiro = useSelector((state) => state.puertaRetiro);
  const userLocation = useSelector((state) => state.userLocation);
  const allOrders = useSelector((state) => state.allOrders);
  const allUsers = useSelector((state) => state.allUsers);
  const ipCliente = useSelector((state) => state.ipCliente);
  const newOrder = useSelector((state) => state.newOrder);
  const userInfo = useSelector((state) => state.userInfo);
  const tripInfo = useSelector((state) => state.tripInfo);
  const [idLanding, setIdLanding] = useState(undefined);
  const [currentTime, setCurrentTime] = useState(undefined);
  const [paso1, setPaso1] = useState(true);
  const [paso2, setPaso2] = useState(false);
  const [precioEnvio, setPrecioEnvio] = useState(undefined);
  const [programar, setProgramar] = useState(false);
  const [newOrderState, setNewOrderState] = useState(undefined);
  const [input, setInput] = useState({
    direccionRetiro: "",
    direccionEntrega: "",
    telefonoNegocio: "",
    pagado: true,
    precioProducto: "",
    nombreCliente: "undefined",
    telefonoCliente: "",
    detallesExtra: "",
    quienAbona: "Retiro",
    costoEnvio: 0,
    clientId: "",
    clienteIp: "",
    time: "",
    pisoDptoRetiro: "",
    pisoDptoEntrega: "",
    horarioEnvio: "",
  });

  useEffect(() => {
    if (!userLocation[0]) {
      getUserLocation().then((lngLat) =>
        dispatch({ type: SET_USER_LOCATION, payload: lngLat })
      );
    }
  }, [userLocation, dispatch]);

  useEffect(() => {
    if (!idLanding && userInfo !== []) {
      setIdLanding(userInfo.id);
    }
  }, [idLanding, userInfo]);

  useEffect(() => {
    setNewOrderState(newOrder);
  }, [newOrder]);

  useEffect(() => {
    if (userInfo === []) {
      const email = JSON.parse(localStorage.getItem("Mail-Usuario-Logueado"));
      dispatch(getUserInfo(email));
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (!ipCliente) {
      dispatch(getIpClient());
    }
    if (
      ipCliente &&
      (JSON.parse(localStorage.getItem("ipClienteLs")) === [] ||
        !JSON.parse(localStorage.getItem("ipClienteLs")))
    ) {
      localStorage.setItem("ipClienteLs", JSON.stringify(ipCliente));
    }
    var today = new Date();
    setCurrentTime(today.toLocaleString("en-US").split(",")[1]);
  }, [ipCliente, dispatch, currentTime]);

  useEffect(() => {
    if (!allOrders[0]) {
      dispatch(getAllOrders(idLanding));
    }
  }, [allOrders, idLanding, dispatch]);

  useEffect(() => {
    setInput({
      ...input,
      clientId: userInfo.id,
      direccionRetiro: direccionRetiro,
      direccionEntrega: direccionEntrega,
      pisoDptoEntrega: puertaEntrega,
      pisoDptoRetiro: puertaRetiro,
      clienteIp: ipCliente,
      telefonoNegocio: userInfo.telefono,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    direccionEntrega,
    direccionRetiro,
    puertaEntrega,
    puertaRetiro,
    ipCliente,
    userInfo,
  ]);

  useEffect(() => {
    if (!allUsers[0]) {
      dispatch(getAllUsers());
    }
  }, [allUsers, dispatch]);

  useEffect(() => {
    if (tripInfo !== {}) {
      const { distance } = tripInfo;
      if (distance >= 0 && distance <= 2) setPrecioEnvio(45);
      if (distance > 2 && distance <= 3) setPrecioEnvio(50);
      if (distance > 3 && distance <= 4.5) setPrecioEnvio(55);
      if (distance > 4.5 && distance <= 6) setPrecioEnvio(60);
      if (distance > 6 && distance <= 7.5) setPrecioEnvio(65);
      if (distance > 7.5 && distance <= 9) setPrecioEnvio(70);
      if (distance > 9 && distance <= 11.5) setPrecioEnvio(75);
      if (distance > 11.5 && distance <= 14) setPrecioEnvio(80);
      if (distance > 14 && distance <= 15) setPrecioEnvio(85);
    }
    setInput({
      ...input,
      costoEnvio: precioEnvio,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripInfo]);

  function handleChange(e) {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  function handleProgramar(e) {
    setInput({
      ...input,
      horarioEnvio: e.target.value,
    });
  }

  function handleSelect(e) {
    setProgramar(true);
    if (e.target.value !== "Programar") {
      setProgramar(false);
    }
    if (e.target.value === "Ahora") {
      setInput({
        ...input,
        horarioEnvio: "Inmediato",
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (
      input.direccionRetiro.length > 1 &&
      input.direccionEntrega.length > 1 &&
      input.telefonoNegocio.length > 1 &&
      input.pagado !== "" &&
      input.nombreCliente.length > 1 &&
      input.telefonoCliente.length > 1 &&
      input.quienAbona.length > 1 &&
      input.horarioEnvio.length > 1
    ) {
      var today = new Date();
      setCurrentTime(today.toLocaleString("en-US"));
      setInput({
        ...input,
        time: today.toLocaleString("en-US"),
      });
      localStorage.setItem("newOrder", JSON.stringify({}));
      setPaso1(false);
      dispatch(createNewOrder(input));
      setPaso2(true);
    } else {
      alert("Completar los campos obligatorios para continuar");
    }
  }
  if (newOrderState) {
    setTimeout(() => {
      window.location.replace(`/#/seguimiento/${newOrderState}`);
      setNewOrderState(undefined);
    }, 4000);
  }

  return (
    <>
      {paso1 && (
        <>
          <GlobalStyle />
          <h2 className={styles.title}>Datos del envio</h2>

          <div className={styles.gral}>
            <div className={styles.inputRetiroEntrega}>
              <InputsDireccion />
            </div>
            <input
              type="text"
              value={input.telefonoCliente}
              maxLength={12}
              name="telefonoCliente"
              placeholder="Teléfono del cliente *"
              onChange={(e) => handleChange(e)}
              className={styles.telefono}
            />
            <select
              onChange={(ev) => handleSelect(ev)}
              className={styles.select}
            >
              <option value="">Seleccionar horario *</option>
              <option value="Ahora">Inmediato</option>
              <option value="Programar">Programar</option>
            </select>
            {programar && (
              <input
                type="datetime-local"
                className={styles.select}
                name="detallesExtra"
                onChange={(e) => handleProgramar(e)}
              />
            )}
            <input
              type="text"
              value={input.detallesExtra}
              maxLength={100}
              name="detallesExtra"
              placeholder="Detalles extra (opcional)"
              onChange={(e) => handleChange(e)}
              className={styles.detalle}
            />
          </div>
          <div className={styles.costoEnvio}>
            {tripInfo.distance && tripInfo.distance < 15 && (
              <>
                {" "}
                <h1>Costo de envío:</h1>
                <h3>${precioEnvio} MXN</h3>
              </>
            )}{" "}
            {!tripInfo.distance && (
              <h4>Ingrese las direcciones para calcular el costo del envío.</h4>
            )}
            {tripInfo.distance > 14 && (
              <h4 className={styles.largaDistancia}>
                Contáctanos por envíos de larga distancia.
              </h4>
            )}
          </div>
          <div className={styles.buttonsMobile}>
            <Link to="/admin">
              <button className={styles.btn}>Inicio</button>
            </Link>
            <button
              className={styles.btn}
              disabled={
                input.direccionEntrega.length < 1 ||
                input.direccionRetiro.length < 1 ||
                input.nombreCliente.length < 1 ||
                input.telefonoCliente.length < 1 ||
                input.horarioEnvio.length < 1 ||
                input.costoEnvio === 0
              }
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Crear envío
            </button>
          </div>
        </>
      )}
      {paso2 && (
        <div className={styles.check}>
          <img
            src={pedidoCreado}
            alt="Pedido creado con éxito"
            height="300px"
          />
        </div>
      )}
    </>
  );
};

export default NuevoEnvio;
