import React from "react";
// import { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./ChangeAddress.module.css";
// import logo from "../../../../assets/logo.png";
import { changeAddress } from "../../../../redux/actions";
import MyInput from "../../../basics/myInput";

export default function ChangeAddress(props) {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [input, setInput] = React.useState({
    password: "",
    newAddress: "",
  });

  function handleChange(e) {
    setInput({
      ...input, //ademas de lo que tiene
      [e.target.name]: e.target.value, //agregale lo que el usuario pone
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (input.newAddress.length > 4) {
      alert("¡Dirección cambiada con éxito!");
      dispatch(changeAddress(props.id, input.password, input.newAddress));
      setInput({
        password: "",
        newAddress: "",
      });
      props.setTrigger(false);
    //   window.location.reload();
    } else {
      alert("La nueva dirección debe tener: Mínimo 4 caracteres ");
    }
    // navigate('');
  }

  return props.trigger ? (
    <div className={styles.popup}>
      {props.children}
      <div className={styles.form}>
        {/* <img src={logo} alt="Blikz" height="45px"/> */}
        <div className={styles.divTitle}>
          <h1 className={styles.title}>Cambiar Dirección</h1>
          <button
            className={styles.close}
            onClick={() => props.setTrigger(false)}
          >
            x
          </button>
        </div>
        {/* <div className={styles.div_input}>
                    <label>Email:</label><br/>
                    <input 
                        value={input.email}
                        name="email"
                        placeholder="Ingresar mail"
                        onChange={(e) => {handleChange(e)}}
                    />
                </div> */}
        <MyInput l="Email" t="text" v={props.email} n="email" />
        <div className={styles.div_input}>
          <label>Contraseña:</label>
          <input
            type="password"
            value={input.password}
            name="password"
            placeholder="Ingresar contraseña actual"
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
        <div className={styles.div_input}>
          <label>Nueva Dirección:</label>
          <input
            type="text"
            value={input.newAddress}
            name="newAddress"
            placeholder="Ingresar la nueva dirección"
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
        <button className={styles.btn} onClick={(e) => handleSubmit(e)}>
          Listo
        </button>
      </div>
    </div>
  ) : (
    ""
  );
}
