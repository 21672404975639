import React from 'react';
import styles from './Header.module.css';
import logoBlikz from '../../../../assets/blikz1.png'

const Header = () => {
  return (
    <div className={styles.gral}>
        <img src={logoBlikz} alt="blikz" height={"30px"}/>
    </div>
  )
}

export default Header