import React, { useEffect } from "react";
import styles from "./Metricas.module.css";
import { getAllUsers } from "../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import blikz from '../../assets/logo.png';

const Metricas = () => {
  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state.allUsers);

  useEffect(() => {
    if (!allUsers[0]) {
      getAllUsers(dispatch);
    }
  }, [allUsers]);
console.log(allUsers,"USUARIOS")
  return (
    <>
    <div className={styles.gral}>
    <img src={blikz} alt="logo" height="50px"/>
    </div>
      <div className={styles.downloadDiv}>
        <h3>Cantidad de registros :</h3>
        <h3 className={styles.nro}>{allUsers.length}</h3>{" "}
      </div>
    </>
  );
};

export default Metricas;
