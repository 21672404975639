import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"
 
mapboxgl.accessToken = 'pk.eyJ1IjoiYmxpa3oiLCJhIjoiY2xhaXRsaTdwMDNiMDNucDdiMTRjMGd3eSJ9.rYBmwLvlx3yJpbtXmb693g';

if (!navigator.geolocation) {
  alert(
    "Tu navegador no permite obtener tu localización, podrás continuar utilizando la app de todas formas"
  );
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
