import React from "react";
import "./App.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import Home from "./components/Landing/ContainersLanding/Home";
// import PassRecovery from "./components/Login/PassRecovery/PassRecovery";
import Main from "./components/Dashboard/Main/Main";
import PanelDeBlikz from "./components/PanelDeBlikz/PanelDeBlikz";
import Register from "./components/Login/Register/Register";
import LandingNegocio from "./components/LandingNegocio/LandingNegocio";
// import DatosNegocio from "./components/LandingNegocio/DatosNegocio/DatosNegocio";
import Seguimiento from "./components/LandingNegocio/Seguimiento/Seguimiento";
import NuevoEnvio from "./components/Dashboard/NuevoEnvio/NuevoEnvio";
import Repartidor from "./components/LandingNegocio/Seguimiento/Repartidor";
import Metricas from "./components/Metricas/Metricas";
// function BlogPost(){
//   let {negocio} = useParams();
// //   return <div>Now showing post {negocio}</div>
// }


function App() {
  return (
    <HashRouter>
      <div className="App">
        <Routes>
          <Route exact path="/" element = {<Home/>}></Route>
          <Route exact path="/admin" element = {<Main/>}></Route>
          {/* <Route path="/admin/:negocio" element = {<Main/>}></Route> */}
          <Route exact path="/iniciarSesion" element={<Login />}/>
          <Route path="/registrarme" element={<Register />}/>
          <Route path="/:username" element={<LandingNegocio/>}/>
          {/* <Route path="/envios/:negocio" element={<LandingNegocio/>} render={({match}) => (<LandingNegocio props={match}/>)}/> */}
          <Route path="/seguimiento/:nroOrden" element={<Seguimiento/>}/>
          <Route path="/repartidor/:nroOrden" element={<Repartidor/>}/>
          <Route path="/crearEnvio" element={<NuevoEnvio/>}/> 
          {/* <Route path="/restorePassword" element={<PassRecovery />}/> */}
          <Route path="/panelBlikz" element={<PanelDeBlikz/>}/>
          <Route path="/metricas" element={<Metricas/>}/>
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
