import React, { useEffect, useState } from "react";
import Aside from "../asideDashboard/asideDashboard";
import AdminHome from "../AdminHome/AdminHome";
import Account from "../Account/Account";
import style from "./Main.module.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders, getUserInfo, login } from "../../../redux/actions";
import MobileMenu from "../asideDashboard/MobileMenu/MobileMenu";

export default function Main() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);

  const [panelActive, setPanelActive] = useState({
    home: true,
    account: false,
  });

  if(!panelActive.home && !panelActive.account){
      panelActive.home = true;
  }

  // Comentar para no loguearte

  useEffect(() => {
    login(dispatch);
    !globalState.login && navigate("/iniciarSesion");
       dispatch(getAllOrders)
       dispatch(getUserInfo)
  }, [dispatch, globalState.login, navigate, globalState.userInfo.id]);

  return (
    <div className={style.main_box}>
      <div className={style.div_aside}>
        <Aside setPanelActive={setPanelActive} panelActive={panelActive} />
        </div>
      <div className={style.div_mobilemenu}>
        <MobileMenu setPanelActive={setPanelActive} panelActive={panelActive} />
      </div>
        <div className={style.div_section}>
        <section>
          {/* <span>renderizado de contenido</span> */}
          {panelActive.home && <AdminHome />}
          {panelActive.account && <Account />}
        </section>
      </div>
    </div>
  );
}
