import React, { useState } from "react";
import styles from "./ButtonNewOrder.module.css";
// import PopupNewTrip from "../PopupNewTrip/PopupNewTrip";
import { Link } from "react-router-dom";
import ws from "../../../../assets/ws.png";
import catalogo from "../../../../assets/Dashboard/catalogo.png";
import prox from "../../../../assets/Dashboard/prox.png";
import entrega from "../../../../assets/Dashboard/entrega1.png";
// import Pacheco from "../../../../assets/LandingNegocio/logoPacheco.jpeg";
// import Blikz from "../../../../assets/logo.png";

const ButtonNewOrder = () => {
  const [show, setShow] = useState(false);
  // const [elegirFlota, setElegirFlota] = useState(false);

  return (
    <>
      <div className={styles.gral}>
      <Link to="/crearenvio">
        <button className={styles.buttons}>
          <img src={entrega} alt="agregarPedido" height="47px" />
          Nuevo envío
        </button>
        </Link>
        <a
          href="https://wa.me/+529613693332"
          target="_blank"
          without
          rel="noreferrer"
        >
          <button className={styles.buttons}>
            <img src={ws} alt="agregarPedido" height="45px" />
            Soporte
          </button>
        </a>

        <button
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          className={styles.buttonProductos}
        >
          <img
            src={show ? prox : catalogo}
            alt="agregarPedido"
            height={show ? "40px" : "45px"}
          />
          Productos
        </button>
      </div>
      {/* {elegirFlota && (
        <div className={styles.popup}>
          <form className={styles.form1}>
            <button
              className={styles.close1}
              onClick={() => setElegirFlota(false)}
            >
              x
            </button>
            <h1 className={styles.titleFlota}>Elije tu flota favorita</h1>
            <div className={styles.btnVhDiv}>
              <Link to="/crearenvio">
                <button
                  className={styles.btnVh}
                  onClick={() => {
                    setElegirFlota(false);
                    // localStorage.setItem("idLanding", JSON.stringify(1));
                  }}
                >
                  <img src={Pacheco} alt="Pacheco" />
                </button>
              </Link>
              <Link to="/crearenvio">
                <button
                  className={styles.btnVh}
                  onClick={() => setElegirFlota(false)}
                >
                  <img src={Blikz} alt="Blikz" />
                </button>
              </Link>
            </div>
          </form>
        </div>
      )} */}
    </>
  );
};

export default ButtonNewOrder;
