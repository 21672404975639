import React from "react";
import { useDispatch } from "react-redux";
import { cancellOrder } from "../../../redux/actions";
import style from "./CancelarPedido.module.css";
import logo from "../../../assets/logo.png";

const CancelarPedido = (props) => {
  const dispatch = useDispatch();

  const cancelHandler = () => {
    // props.setActivateConfirm((prev) => !prev);
    props.setTrigger(false);
  };
  const acceptHandler = () => {
    dispatch(cancellOrder(props.idOrder, "cancelado"));
    props.setTrigger(false);
    props.setCloseAll(false);
    // navigate("/admin")
  };
  return props.trigger ? (
    <div className={style.container}>
      <div className={style.confirm_box}>
        <img src={logo} alt="Blikz" height="45px" />
        <h3>¿Cancelar Pedido?</h3>
        <div className={style.btns_div}>
          <button onClick={acceptHandler}>Aceptar</button>
          <button onClick={cancelHandler}>Volver</button>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default CancelarPedido;
