import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getAllUsers } from "../../redux/actions";
import logoPachecos from "../../assets/LandingNegocio/logoPacheco.jpeg";
import logoDft from "../../assets/LandingNegocio/dftFlota.png";
import error404 from "../../assets/LandingNegocio/error404.png";
import styles from "./LandingNegocio.module.css";
import TopBanner from "./TopBanner";
import { useParams } from "react-router";
// import CardsSeguimiento from "./SliderSeguimiento/CardsSeguimiento/CardsSeguimiento"
// import SliderSeguimiento from "./SliderSeguimiento/SliderSeguimiento";
import OrdersBox from "./OrdersBox/OrdersBox";
// import PopupNewTrip from "../Dashboard/AdminHome/PopupNewTrip/PopupNewTrip";
// import DatosEnvio from "./DatosEnvio/DatosEnvio";

const LandingNegocio = () => {
  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state.allUsers);
  let { username } = useParams();
  const infoNegocio = allUsers.find(
    (negocio) => negocio.usuario === username.toLocaleLowerCase()
  );
  const [errorUrl, setErrorUrl] = useState(false);

  useEffect(() => {
    if (!allUsers[0]) {
      dispatch(getAllUsers());
    }
    if (infoNegocio === undefined) {
      setTimeout(() => {
        setErrorUrl(true);
      }, 2000);
    }
    if (infoNegocio !== undefined) {
      // dispatch(setIdLanding(infoNegocio.id))
      localStorage.setItem("idLanding", JSON.stringify(infoNegocio.id));
    }
  }, [allUsers, infoNegocio, dispatch]);

  // const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   if (userData.length < 1) {
  //     const email = JSON.parse(localStorage.getItem("Mail-Usuario-Logueado"));
  //     dispatch(getUserInfo(email));
  //   }
  // }, [dispatch, userData]);

  // useEffect(() => {
  //   if(allUser)
  //   allUsers.find(el => el.negocio)
  // }, [])

  return (
    <>
      <TopBanner />
      {infoNegocio !== undefined ? (
        <>
          <div className={styles.gral}>
            {infoNegocio.usuario === "adminroot" ? (
              <img
                src={logoPachecos}
                alt="logoEmpresa"
                height="140px"
                width="140px"
              />
            ) : (
              <img
                src={logoDft}
                alt="logoEmpresa"
                height="150px"
                width="150px"
              />
            )}
            {/* <DatosEnvio paso1={open} setPaso1={setOpen} id={userData.id} /> */}
            <h2>{infoNegocio.negocio}</h2>
            <div className={styles.buttons}>
              <Link to="/crearenvio">
                <button>Crear envío</button>
              </Link>
              {/* <Link to="/datosEnvio">
          <button>Un cliente</button>
        </Link> */}
            </div>
            {/* <CardsSeguimiento/> */}
            <OrdersBox />
          </div>
        </>
      ) : (
        errorUrl && (
          <div className={styles.gralError}>
            <img src={error404} alt="ERORR 404" height="220px" />
            <h3>
              No encontramos el negocio que buscabas, revisa nuevamente la URL
            </h3>
          </div>
        )
      )}
    </>
  );
};

export default LandingNegocio;
