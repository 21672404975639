import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./ChangeEmail.module.css";
import { changeEmail } from "../../../../redux/actions";

import MyInput from "../../../basics/myInput";

export default function ChangeEmail(props) {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState({});
  const [input, setInput] = useState({
    password: "",
    newEmail: "",
  });
  
  const loginValidate = (form) => {
  let error = {};
    const emailRegExp =
      /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    if (form.hasOwnProperty("newEmail")) {
      if (form.newEmail.length === 0) error.newEmail = "Este campo es obligatorio";
      else if (emailRegExp.test(form.newEmail) === false) {
        error.newEmail = "Debe escribir un email válido";
      } 
      else if (props.email === input.newEmail){
        error.newEmail = "El nuevo email no puede ser igual al actual";
      }
       else {
        //si cumple con todas las condiciones, seteo en blanco y no renderizará nada
        error.newEmail = undefined;
      }
    }
    return error;
  }

  function errorsHandler(e) {
    let form = { [e.target.name]: input[e.target.name] };
    let fails = loginValidate(form);
    setErrors((prev) => ({ ...prev, ...fails }));
  }

  function handleChange(e) {
    setInput({
      ...input, //ademas de lo que tiene
      [e.target.name]: e.target.value, //agregale lo que el usuario pone
    });
    console.log(e.target.name,":",e.target.value)
  }

  function handleSubmit(e) {
    e.preventDefault();
    if(errors.newEmail === undefined){

    // let user= props.users.find(e=>e.email===input.email)
    // user.email = input.newEmail
    // console.log('submit', user)
  
    dispatch(changeEmail(input.newEmail, input.password, props.id))

    setInput({
      password: "",
      newEmail: ""
    });
    alert("¡Email modificado con éxito!")
    props.setTrigger(false)
    window.location.reload()
  }else{
    alert("El nuevo email no cumple con el formato necesario")
  }
  }

  return props.trigger ? (
    <div className={styles.popup}>
      {props.children}
      <form onSubmit={(e) => handleSubmit(e)}
        className={styles.form}>
        <div className={styles.divTitle}>
          <h1 className={styles.title}>Cambiar correo electrónico</h1>
          <button className={styles.close}
            onClick={() => props.setTrigger(false)}>
            x
          </button>
        </div>

        <MyInput l='Correo electrónico actual' t='text' v={props.email} n='email'/>

        <div className={styles.div_input}>
          <label>Contraseña actual:</label>
          <input
            type="password"
            value={input.password}
            name="password"
            placeholder="Ingresar contraseña"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className={styles.div_input}>
          <label>Nuevo correo electrónico:</label>
          <input
            type="text"
            value={input.newEmail}
            name="newEmail"
            placeholder="Ingresar nuevo correo electrónico"
            onChange={(e) => handleChange(e)}
            onBlur={(e) => errorsHandler(e)}
            className={errors.newEmail? styles.errorInput: styles.input1}
          />
           <br />
            {errors && <small>{errors.newEmail}</small>}
        </div>
        <button className={styles.btn} type="submit">
          Cambiar
        </button>
      </form>
    </div>
  ) : ("");
}
