import axios from "axios";
import { saveToken } from "../../components/Login/controllers/tokenFunctions";

// middlewares validacion token

// comentado para evitar warnings
// import { verifyTokenRole, sendKey } from "../../middlewares/verifyToken";
import { sendKey } from "../../middlewares/verifyToken";
// import searchApi from "../../apis/searchApi";

const REACT_APP_API = process.env.REACT_APP_API
  ? process.env.REACT_APP_API
  : "http://localhost:3001";
// estos son ejemplos

export const LOGIN = "LOGIN";
export const LOG_OUT = "LOG_OUT";
export const LOADING = "LOADIN";
export const ERROR_MODAL = "ERROR_MODAL";
export const CLEAR_PRODUCT_DETAIL = "CLEAR_PRODUCT_DETAIL";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const POST_USERS = "POST_USERS";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_SUBCATEGORIE_BY_ID = "GET_SUBCATEGORIE_BY_ID";
export const POST_NEW_CATEGORY = "POST_NEW_CATEGORY";
export const POST_NEW_SUBCATEGORY = "POST_NEW_SUBCATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const DELETE_USER = "DELETE_USER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const POST_CUSTOMER = "POST_CUSTOMER";
export const GET_ALL_PRODUCTS_CLIENT = "GET_ALL_PRODUCTS_CLIENT";
export const GET_DETAIL_CLIENT = "GET_DETAIL_CLIENT";
export const GET_PRODUCTS_BY_NAME_CLIENTS = "GET_PRODUCTS_BY_NAME_CLIENTS";
export const ADD_TO_CART = "ADD_TO_CART";
export const CLEAR_CART = "CLEAR_CART";
export const REMOVE_ALL_FROM_CART = "REMOVE_ALL_FROM_CART";
export const REMOVE_ONE_FROM_CART = "REMOVE_ONE_FROM_CART";
export const ADD_ONE_TO_CART = "ADD_ONE_TO_CART";
export const FILL_CART = "FILL_CART";
export const SET_FILTERS = "SET_FILTERS";
export const FILTER_BY_FEATURED_BTN = "FILTER_BY_FEATURED_BTN";
export const FILTER_BY_DISCOUNTED_BTN = "FILTER_BY_DISCOUNTED_BTN";
export const FILTER_BY_FEATURED = "FILTER_BY_FEATURED";
export const ORDER_BY_PRICE = "ORDER_BY_PRICE";
export const ORDER_BY_PRICE_CAT = "ORDER_BY_PRICE_CAT";
export const FILTER_BY_DISCOUNT = "FILTER_BY_DISCOUNT";
export const GET_ALL_INVOICES = "GET_ALL_INVOICES";
export const FILTER_BY_CATEGORY = "FILTER_BY_CATEGORY";
export const FILTER_BY_FEATURED_CAT = "FILTER_BY_FEATURED_CAT";
export const GET_PRODUCTS_BY_CATEGORY = "GET_PRODUCTS_BY_CATEGORY";
export const POST_LOGIN_CUSTOMER = "POST_LOGIN_CUSTOMER";
export const POST_EMAIL = "POST_EMAIL";
export const URL_MP = "URL_MP";
export const GET_INVOICE_BY_CLIENT = "GET_INVOICE_BY_CLIENT";
export const USER_INFO = "USER_INFO";
export const ALL_ORDERS = "ALL_ORDERS";
export const ORDER_DETAIL = "ORDER_DETAIL";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const ALL_ORDERS_PANEL = "ALL_ORDERS_PANEL";
export const INPUT1 = "INPUT1";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_LUGAR_RETIRO = "SET_LUGAR_RETIRO";
export const SET_LUGAR_ENTREGA = "SET_LUGAR_ENTREGA";
export const SET_PUERTA_RETIRO = "SET_PUERTA_RETIRO";
export const SET_PUERTA_ENTREGA = "SET_PUERTA_ENTREGA";
export const VERIFY_USER_NAME = "VERIFY_USER_NAME";
export const LISTA_LUGARES_RETIRO = "LISTA_LUGARES_RETIRO";
export const LISTA_LUGARES_ENTREGA = "LISTA_LUGARES_ENTREGA";
export const SET_MARKER_RETIRO = "SET_MARKER_RETIRO";
export const SET_MARKER_ENTREGA = "SET_MARKER_ENTREGA";
export const SET_TRIP_INFO = "SET_TRIP_INFO";
export const SET_BOUNDS = "SET_BOUNDS";
export const ORDER_CREATED = "ORDER_CREATED";
export const PRECIO_ENVIO = "PRECIO_ENVIO";
export const NEW_ORDER = "NEW_ORDER";
// Used in Account component
export const UPDATE_USER = "UPDATE_USER";
export const REFRESH_STATE = "REFRESH_STATE";
export const IP_CLIENTE = "IP_CLIENTE";
export const COSTO_ENVIO_STATE = "COSTO_ENVIO_STATE";

export const login = async (dispatch, email, password) => {
  try {
    let credential = await axios.post(
      `${REACT_APP_API}/login`,
      {
        email,
        password,
        // headerProxy,
      },
      sendKey()
    );
    saveToken(credential.data.data.token);
    dispatch({
      type: LOGIN,
      payload: credential.data.login,
    });
    // dispatch(getUserInfo(credential.data.currentUser))
    // dispatch({
    //   type: USER_INFO,
    //   payload: credential.data.currentUser
    // });

    // comentado para quitar el warning
    // const roleUser = verifyTokenRole(credential); // Retorna el rol del usuario administrativo

    return credential;
  } catch (error) {
    loading(dispatch, false);
    dispatch({
      type: LOGIN,
      payload: null,
    });

    console.log(error);
  }
};

export const loading = (dispatch, payload) => {
  dispatch({
    type: LOADING,
    payload,
  });
};
export const errorModal = (dispatch, payload) => {
  dispatch({
    type: ERROR_MODAL,
    payload,
  });
};

// export function getAllProducts() {
//   return async function (dispatch) {
//     dispatch({
//       type: GET_PRODUCTS_INIT,
//     });
//     var json = await axios.get(`${REACT_APP_API}/getAllProducts`, sendKey());
//     return dispatch({
//       type: GET_ALL_PRODUCTS,
//       payload: json.data,
//     });
//   };
// }
//d
//getAll for client
// export function getProductsClient() {
//   return async function (dispatch) {
//     dispatch({
//       type: GET_PRODUCTS_INIT,
//     });
//     var json = await axios.get(`${REACT_APP_API}/getProductsClient`, sendKey());
//     return dispatch({
//       type: GET_ALL_PRODUCTS_CLIENT,
//       payload: json.data,
//     });
//   };
// }

// export function getProductsByName(name) {
//   return async function (dispatch) {
//     try {
//       dispatch({
//         type: GET_PRODUCTS_INIT,
//       });
//       var json = await axios.get(
//         `${REACT_APP_API}/productDetail/detail0/?nameProduct=` + name,
//         sendKey()
//       );
//       return dispatch({
//         type: GET_PRODUCTS_BY_NAME,
//         payload: json.data,
//       });
//     } catch (error) {
//       // return dispatch({
//       //   type: GET_PRODUCTS_BY_NAME,
//       //   payload: null,
//       // });
//       // console.log(error)
//     }
//   };
// }

// export function getProductsByNameClients(name) {
//   return async function (dispatch) {
//     try {
//       dispatch({
//         type: GET_PRODUCTS_INIT,
//       });
//       var json = await axios.get(
//         `${REACT_APP_API}/getProDetailClient/detail0/?nameProduct=` + name,
//         sendKey()
//       );
//       return dispatch({
//         type: GET_PRODUCTS_BY_NAME_CLIENTS,
//         payload: json.data,
//       });
//     } catch (error) {
//       return dispatch({
//         type: GET_PRODUCTS_BY_NAME_CLIENTS,
//         payload: null,
//       });
//       // console.log(error)
//     }
//   };
// }

// export function getProductDetail(idProduct) {
//   return async function (dispatch) {
//     try {
//       dispatch({
//         type: GET_PRODUCTS_INIT,
//       });
//       var json = await axios.get(
//         `${REACT_APP_API}/productDetail/detail` + idProduct,
//         sendKey()
//       );
//       return dispatch({
//         type: GET_PRODUCT_DETAIL,
//         payload: json.data,
//       });
//     } catch (error) {
//       // return dispatch({
//       //   type: GET_PRODUCT_DETAIL,
//       //   payload: null,
//       // });
//       // console.log(error)
//     }
//   };
// }

// export function getProductsByCategory(categoryId) {
//   return async function (dispatch) {
//     try {
//       // dispatch({
//       //   type: GET_PRODUCTS_INIT,
//       // });
//       var json = await axios.get(
//         `${REACT_APP_API}/getcatbyid/` + categoryId
//       );
//       return dispatch({
//         type: GET_PRODUCTS_BY_CATEGORY,
//         payload: json.data,
//       });
//     } catch (error) {
//       // return dispatch({
//       //   type: GET_PRODUCT_DETAIL,
//       //   payload: null,
//       // });
//       // console.log(error)
//     }
//   };
// }

// export function getDetailClients(idProduct) {
//   return async function (dispatch) {
//     try {
//       var json = await axios.get(
//         `${REACT_APP_API}/getProDetailClient/detail` + idProduct,
//         sendKey()
//       );
//       return dispatch({
//         type: GET_DETAIL_CLIENT,
//         payload: json.data,
//       });
//     } catch (error) {
//       return dispatch({
//         type: GET_DETAIL_CLIENT,
//         payload: null,
//       });
//       // console.log(error)
//     }
//   };
// }

// export function clearProductDetail() {
//   return {
//     type: CLEAR_PRODUCT_DETAIL,
//   };
// }

// export function createProduct(payload) {
//   return async function (dispatch) {
//     var response = await axios.post(
//       `${REACT_APP_API}/createProduct`,
//       payload,
//       sendKey()
//     );
//     // console.log(response);
//     return response;
//   };
// }

// export const updateProduct = async (dispatch, product) => {
//   console.log("recibido,", product);
//   try {
//     let response = await axios.put(
//       `${REACT_APP_API}/updateProduct`,
//       product,
//       sendKey()
//     );
//     console.log("respuesta de update", response);
//     await dispatch(getAllProducts());
//     return dispatch({
//       type: UPDATE_PRODUCT,
//     });
//   } catch (err) {
//     console.log("error en updateProduct", err);
//   }
// };

// export const updateUser = async (dispatch, user) => {
//   console.log("updateUser recibido:", user);
//   try {
//     let response = await axios.put(
//       `${REACT_APP_API}/updateUser`,
//       user,
//       sendKey()
//     );
//     console.log("respuesta de update user", response);
//     let json = await axios.get(`${REACT_APP_API}/getAllUsers`, sendKey());
//     return dispatch({
//       type: UPDATE_USER,
//       payload: json.data,
//     });
//   } catch (err) {
//     console.log("error en updateUser", err);
//   }
// };

export function createUser(payload) {
  return async function () {
    try {
      let json = await axios.post(
        `${REACT_APP_API}/createUser`,
        payload,
        sendKey()
      );
      alert("Usuario creado con éxito!");
      return {
        type: POST_USERS,
        payload: json.data,
      };
    } catch (err) {
      console.log("Error creando usuario", err);
    }
  };
}

// export function getAllCategories() {
//   return async function (dispatch) {
//     try {
//       let json = await axios.get(`${REACT_APP_API}/categories`, sendKey());
//       return dispatch({
//         type: GET_ALL_CATEGORIES,
//         payload: json.data,
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };
// }

// export function getSubcategorieById(id) {
//   return async function (dispatch) {
//     try {
//       let json = await axios.get(`${REACT_APP_API}/getSubcat/` + id, sendKey());
//       return dispatch({
//         type: GET_SUBCATEGORIE_BY_ID,
//         payload: json.data,
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };
// }

// export function createCategory(payload) {
//   return async function () {
//     let json = await axios.post(
//       `${REACT_APP_API}/createCat`,
//       payload,
//       sendKey()
//     );
//     return json;
//   };
// }

// export function createSubcategory(payload) {
//   return async function () {
//     let json = await axios.post(
//       `${REACT_APP_API}/createSubCat`,
//       payload,
//       sendKey()
//     );
//     return json;
//   };
// }

// export function deleteCategory(id) {
//   return async function () {
//     let json = await axios.delete(`${REACT_APP_API}/delCat/` + id, sendKey());
//     return json;
//   };
// }

// export function deleteSubcategory(id) {
//   return async function () {
//     let json = await axios.delete(
//       `${REACT_APP_API}/delSubCat/` + id,
//       sendKey()
//     );
//     return json;
//   };
// }

export function deleteUser(id) {
  return async function () {
    let json = await axios.delete(
      `${REACT_APP_API}/deleteUser/` + id,
      sendKey()
    );
    return json;
  };
}

// export function getCustomer(email) {
//   return async function (dispatch) {
//     let json = await axios.get(
//       `${REACT_APP_API}/getCustomer`,
//       email,
//       sendKey()
//     );
//     return dispatch({
//       type: GET_CUSTOMER,
//       payload: json.data,
//     });
//   };
// }

// export function createCustomer(payload) {
//   return async function () {
//     let json = await axios.post(
//       `${REACT_APP_API}/createCustomer`,
//       payload,
//       sendKey()
//     );
//     return json;
//   };
// }

//Cart
// export function addToCart(product, amount) {
//   return {
//     type: ADD_TO_CART,
//     payload: { product, amount },
//   };
// }

// export function setFilters(payload) {
//   return {
//     type: SET_FILTERS,
//     payload,
//   };
// }

// export function filterByFeaturedBtn(payload) {
//   // console.log(payload)
//   return {
//     type: FILTER_BY_FEATURED_BTN,
//     payload,
//   };
// }

// export function filterByDiscountedBtn(payload) {
//   // console.log(payload)
//   return {
//     type: FILTER_BY_DISCOUNTED_BTN,
//     payload,
//   };
// }

// export function filterByCategory(payload) {
//   //console.log(payload)
//   return {
//     type: FILTER_BY_CATEGORY,
//     payload,
//   };
// }

// export function filterByFeatured(payload) {
//   // console.log(payload)
//   return {
//     type: FILTER_BY_FEATURED,
//     payload,
//   };
// }

// export function filterByFeaturedCat(payload) {
//   // console.log(payload)
//   return {
//     type: FILTER_BY_FEATURED_CAT,
//     payload,
//   };
// }

// export function orderByPrice(payload) {
//   // console.log(payload)
//   return {
//     type: ORDER_BY_PRICE,
//     payload,
//   };
// }

// export function orderByPriceCat(payload) {
//   // console.log(payload)
//   return {
//     type: ORDER_BY_PRICE_CAT,
//     payload,
//   };
// }

// export function filterByDiscount(payload) {
//   // console.log(payload)
//   return {
//     type: FILTER_BY_DISCOUNT,
//     payload,
//   };
// }

// export function getAllInvoices() {
//   return async function (dispatch) {
//     let json = await axios.get(`${REACT_APP_API}/getAllInvoices`, sendKey());
//     return dispatch({
//       type: GET_ALL_INVOICES,
//       payload: json.data,
//     });
//   };
// }

// export function loginCustomer(payload) {
//   console.log("ACTION<<<<<<<<<<<<<<<<<<", payload);
//   return async function (dispatch) {
//     let json = await axios.post(`${REACT_APP_API}/loginCostumer`, payload);
//     console.log("JSOn<<<<<<<<<<<<<<<<<<", json);
//     return dispatch({
//       type: POST_LOGIN_CUSTOMER,
//       payload: json.data,
//     });
//   };
// }

// export function postEmail(payload){
//   return async function (dispatch) {
//       var response = await axios.post(`${REACT_APP_API}/sendMail`, payload);
//       console.log(response);
//       return response;
//   }
// }

// export function sendToMP(payload){
//     return async function (dispatch) {
//       try {
//      let json = await axios.post(`${REACT_APP_API}/mp`,payload);
//      localStorage.setItem("urlMP", JSON.stringify(json.data.url))
//       }catch(error){
//         console.log(error)
//       }
//     }
//   }

// export function sendToMpSuccess(payload){
//   return async function (dispatch) {
//     try {
//     await axios.post(`${REACT_APP_API}/mpsuccess`,payload);
//     console.log('---------success----------->>',payload);
//     }catch(error){
//       console.log(error)
//     }
//   }
// }

// export function getInvoiceByClient(clientId) {
//   return async function (dispatch) {
//     let json = await axios.get(
//       `${REACT_APP_API}/getInvoiceByClient/`+ clientId,
//       sendKey()
//     );
//     return dispatch({
//       type: GET_INVOICE_BY_CLIENT,
//       payload: json.data,
//     });
//   };
// }

export function getUserInfo(email) {
  return async function (dispatch) {
    let json = await axios.post(`${REACT_APP_API}/getUser`, { email: email });
    if (json) {
      return dispatch({
        type: USER_INFO,
        payload: json.data,
      });
    } else {
      console.log("error para pedir el user");
    }
  };
}

export function createNewOrder(payload) {
  return async function (dispatch) {
    let json = await axios.post(
      `${REACT_APP_API}/createOrder`,
      payload
      // sendKey()
    );
    if (json) {
      return dispatch({
        type: NEW_ORDER,
        payload: json.data.data,
      });
    }
    return json;
  };
}

export function input1(input) {
  return async function (dispatch) {
    return dispatch({
      type: INPUT1,
      payload: input,
    });
  };
}

export function getAllUsers() {
  return async function (dispatch) {
    let json = await axios.get(`${REACT_APP_API}/getAllUsers`, sendKey());
    return dispatch({
      type: GET_ALL_USERS,
      payload: json.data,
    });
  };
}

// export function verifyUserName(usuario) {
//   return async function (dispatch) {
//     let json = await axios.post(`${REACT_APP_API}/verifyUserName`, sendKey(),{usuario:'frauen'});
//     return dispatch({
//       type: VERIFY_USER_NAME,
//       payload: json.data,
//     });
//   };
// }

export function getAllOrders(id) {
  return async function (dispatch) {
    let json = await axios.post(`${REACT_APP_API}/getAllOrders`, { id });
    return dispatch({
      type: ALL_ORDERS,
      payload: json.data,
    });
  };
}

export function getAllOrdersPanel() {
  return async function (dispatch) {
    let json = await axios.get(`${REACT_APP_API}/getAllOrdersPanel`);
    return dispatch({
      type: ALL_ORDERS_PANEL,
      payload: json.data,
    });
  };
}

export function getOrderDetail(id) {
  return async function (dispatch) {
    let json = await axios.get(`${REACT_APP_API}/getOrderDetail/` + id);
    return dispatch({
      type: ORDER_DETAIL,
      payload: json.data,
    });
  };
}

export function cancellOrder(id, canceladoV) {
  return async function () {
    let json = await axios.put(`${REACT_APP_API}/updateOrderCancelledStatus`, {
      id,
      canceladoV,
    });
    return json;
  };
}

export function retiredOrder(id, estado) {
  return async function () {
    let json = await axios.put(`${REACT_APP_API}/updateOrderDeliveredStatus`, {
      id,
      estado,
    });
    return json;
  };
}

export function updateOrderCost(id, costoEnvio) {
  return async function (dispatch) {
    await axios.put(`${REACT_APP_API}/updateOrderCost`, {
      id,
      costoEnvio,
    });
    return dispatch({
      type: COSTO_ENVIO_STATE,
      payload: true,
    });
  };
}

export function transportedBy(id, repartidor) {
  return async function () {
    let json = await axios.put(`${REACT_APP_API}/transport`, {
      id,
      repartidor,
    });
    return json;
  };
}

export function changeEmail(nuevoEmail, password, id) {
  return async function () {
    let json = await axios.put(
      `${REACT_APP_API}/changeEmail`,
      { id, password, nuevoEmail },
      sendKey()
    );
    if (json) {
      localStorage.setItem("Mail-Usuario-Logueado", JSON.stringify(nuevoEmail));
    }
    return json;
  };
}

export function changePassword(id, password, newPassword) {
  return async function (dispatch) {
    let json = await axios.put(
      `${REACT_APP_API}/changePassword`,
      { id, password, newPassword },
      sendKey()
    );
    if (json) {
      return dispatch({
        type: CHANGE_PASSWORD,
        payload: json.data,
      });
    }
    return json;
  };
}

// export function setIdLanding(id){
//   return function (dispatch){
//   return dispatch({type: ID_LANDING, payload: id })
// }
// }

export function changeAddress(id, password, newAddress) {
  return async function () {
    let json = await axios.put(
      `${REACT_APP_API}/changeAddress`,
      { id, password, newAddress },
      sendKey()
    );
    return json;
  };
}

export function changePhone(id, password, newPhone) {
  return async function () {
    let json = await axios.put(
      `${REACT_APP_API}/changePhone`,
      { id, password, newPhone },
      sendKey()
    );
    return json;
  };
}

export function getIpClient() {
  return async function (dispatch) {
    try {
      let json = await axios.get("https://api.ipify.org?format=json");
      if (json) {
        return dispatch({
          type: IP_CLIENTE,
          payload: json.data.ip,
        });
      }
      return json;
    } catch (error) {
      console.log(error);
    }
  };
}
// export const buscarLugaresEntrega = async (query, userLocation) => {
//   return async function (dispatch) {
//   if(query.length === 0) return []; //Limpiar state
//   if(!userLocation) throw new Error('No hay ubicación del usuario');

//   const resp = await searchApi.get(`/${ query }.json`,{
//     params: {
//       proximity: userLocation.join(',')
//     }
//   })
//   return dispatch({
//     type: SET_PLACES_ENTREGA,
//     payload: resp.data.features
//   })
// }
// }

// export function buscarLugaresRetiro(respuesta) {
//   localStorage.setItem("Lugar-Retiro", JSON.stringify(respuesta));
//   return async function(dispatch) {
//     console.log(respuesta, "RESPUESTAAA Antes")
//     return dispatch({
//     type: SET_PLACES_RETIRO,
//     payload: JSON.parse(localStorage.getItem("Lugar-Retiro"))
//   })
// }
// }
