import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Register.module.css";
import {
  loading,
  login,
  createUser,
  verifyUserName,
} from "../../../redux/actions";
import Loader from "../../Loader/Loader";
import Error from "../ErrorPopUp/Error";
// import logo from "../../../assets/tu.png";
import blikz from "../../../assets/logo.png";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import tienda from "../../../assets/tienda1.png";
import consumidor from "../../../assets/hombre.png";

export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const customer = useSelector((state) => state.customer);
  const userNameNotAvailable = useSelector((state) => state.userNameAvailable);
  const [errors, setErrors] = useState({});
  // const [image, setImage] = useState([]);
  const [userNotAvailable, setUserNotAvailable] =
    useState(userNameNotAvailable);
  const [form1, setForm1] = useState(true);
  const [, setForm2] = useState(false);
  const [registerType, setRegisterType] = useState("");
  const [active, setActive] = useState({
    consumidor: false,
    tienda: false,
  });
  // const [form3, setForm3] = useState(false);
  // setForm1(true);
  const [input, setInput] = useState({
    email: "",
    contraseña: "",
    contraseña2: "",
    negocio: "",
    usuario: "",
    direccion: "",
    imagen: registerType,
    telefono: "",
    status: true,
  });
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAigElg1D8N6TMP0LZla2CP9yJ0HxCT6bY",
    libraries: ["places"],
  });

  // const [image,setImage] = useState([]);

  // useEffect(() => {
  //   setUserNotAvailable(userNameNotAvailable)
  // }, [userNameNotAvailable])

  useEffect(() => {
    if (customer && customer[0]) {
      //que exista y que no este vacio
      localStorage.setItem("cliente", JSON.stringify(customer));
    }
    if (customer && !customer[0]) {
      //si esta vacio
      JSON.parse(localStorage.getItem("cliente"));
    }
  }, [customer]);

  const loginValidate = (form) => {
    let error = {};
    const emailRegExp =
      /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    if (form.hasOwnProperty("usuario")) {
      if (form.usuario.length === 0)
        error.usuario = "Este campo es obligatorio";
      else if (form.usuario.split("").some((e) => e === " "))
        error.usuario = "No se admiten espacios en blanco";
      else if (userNotAvailable)
        error.usuario = "Ya existe este nombre de usuario";
    } else {
      //si cumple con todas las condiciones, seteo en blanco y no renderizará nada
      error.usuario = undefined;
    }

    if (form.hasOwnProperty("email")) {
      if (form.email.length === 0) error.email = "Este campo es obligatorio";
      else if (emailRegExp.test(form.email) === false) {
        error.email = "Debe escribir un email válido";
      } else {
        //si cumple con todas las condiciones, seteo en blanco y no renderizará nada
        error.email = undefined;
      }
    }

    if (form.hasOwnProperty("contraseña")) {
      if (form.contraseña.length < 1)
        error.contraseña = "Este campo es obligatorio";
      else if (form.contraseña.split("").some((e) => e === " "))
        error.contraseña = "No se admiten espacios en blanco";
      else error.contraseña = undefined;
    }

    if (form.hasOwnProperty("contraseña2")) {
      if (form.contraseña2.length < 1)
        error.contraseña2 = "Este campo es obligatorio";
      else if (form.contraseña2.split("").some((e) => e === " ")) {
        error.contraseña2 = "No se admiten espacios en blanco";
      }
      // else error.contraseña2 = undefined;
      else if (
        input.contraseña.length > 1 &&
        input.contraseña2.length > 1 &&
        input.contraseña !== input.contraseña2
      ) {
        error.contraseña2 = "Las contraseñas no coinciden";
      } else {
        //si cumple con todas las condiciones, seteo en blanco y no renderizará nada
        error.contraseña2 = undefined;
      }
    }

    // if (!image[0]) {
    //   error.imagen = "Este campo es obligatorio";
    // } else {
    //   error.imagen = undefined;
    // }

    if (form.hasOwnProperty("negocio")) {
      if (form.negocio.length < 1) error.negocio = "Este campo es obligatorio";
      else error.negocio = undefined;
    }
    // if (form.hasOwnProperty("direccion")) {
    //   if (input.direccion.length < 1)
    //     error.direccion = "Este campo es obligatorio";
    //   else error.direccion = undefined;
    // }
    // if (form.hasOwnProperty("telefono")) {
    //   if (input.telefono.length < 1)
    //     error.telefono = "Este campo es obligatorio";
    //   else error.telefono = undefined;
    // }

    return error;
  };

  function handlePlaceChanged(autocomplete) {
    const selectedPlace = autocomplete.getPlace();
    if (selectedPlace && selectedPlace.formatted_address) {
      setInput({
        ...input, //ademas de lo que tiene
        direccion: selectedPlace.formatted_address, //agregale lo que el usuario pone
      });
    }
  }

  function handleAutocompleteLoad(autocomplete) {
    autocomplete.addListener("place_changed", () =>
      handlePlaceChanged(autocomplete)
    );
  }

  function handleChangeMinuscula(e) {
    if (
      e.target.name === "contraseña" ||
      e.target.name === "contraseña2" ||
      e.target.name === "email"
    ) {
      setInput({
        ...input,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    }
  }

  function handleChange(e) {
    if (
      e.target.name !== "usuario" ||
      e.target.name !== "contraseña" ||
      e.target.name !== "contraseña2" ||
      e.target.name !== "email"
    ) {
      setInput({
        ...input,
        [e.target.name]: e.target.value,
      });
    }
  }

  function handleUserName(e) {
    setInput({
      ...input,
      usuario: e.target.value.toLowerCase(),
    });
    // setTimeout(() => {
    //   verifyUserName(input.usuario);
    // }, 4000);
  }

  // const changeInput = (e) => {
  //   const files = e.currentTarget.files;
  //   Object.keys(files).forEach((i) => {
  //     const file = files[i];
  //     let url = URL.createObjectURL(file);
  //     setImage(url);
  //   });
  //   setInput({
  //     ...input, //ademas de lo que tiene
  //     [e.target.name]: e.target.value, //agregale lo que el usuario pone
  //   });
  // };

  const openForm2 = () => {
    setForm1(false);
    setForm2(true);
  };

  const openForm1 = () => {
    setForm2(false);
    setForm1(true);
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (
      errors.negocio === undefined &&
      errors.direccion === undefined &&
      errors.telefono === undefined &&
      errors.email === undefined &&
      errors.contraseña === undefined &&
      errors.contraseña2 === undefined &&
      errors.usuario === undefined
      // input.imagen.length > 1
    ) {
      alert("¡Usuario Creado!");
      // input.imagen = URL.revokeObjectURL(input.imagen)
      const payload = {
        email: input.email,
        contraseña: input.contraseña,
        negocio: input.negocio,
        usuario: input.usuario,
        direccion: input.direccion,
        telefono: input.telefono,
        status: input.status,
      };
      dispatch(createUser(payload));
      navigate("/");
      setInput({
        email: "",
        contraseña: "",
        contraseña2: "",
        negocio: "",
        usuario: "",
        direccion: "",
        telefono: "",
      });
      // dispatch(createCustomer(input));
      // dispatch(loginCustomer(input));
    } else {
      alert("¡Algún campo tiene un error o no agregaste ninguna foto!");
    }
  }

  function errorsHandler(e) {
    let form = { [e.target.name]: input[e.target.name] };
    let fails = loginValidate(form);
    setErrors((prev) => ({ ...prev, ...fails }));
  }

  useEffect(() => {
    login(dispatch);
    loading(dispatch, false);
  }, [dispatch]);

  if (!isLoaded) {
    return <h1> </h1>;
  }
  return (
    <>
      <div className={styles.gral}>
        {globalState.loading && <Loader />}
        {form1 === true ? (
          <>
            <div className={styles.form1}>
              <img
                className={styles.blikzlogo1}
                src={blikz}
                alt="Blikz"
                height="45px"
              />
              <h2 className={styles.title}>¿Qué tipo de usuario eres?</h2>
              <div className={styles.btnUserDiv}>
                <button
                  className={`${styles.btnUser} ${
                    active.consumidor && styles.active
                  }`}
                  // className={styles.btnUser}
                  type="submit"
                  id="account"
                  onClick={() => {
                    setRegisterType("consumidor");
                    setActive({ tienda: false, consumidor: true });
                  }}
                >
                  <img
                    className={styles.consumidor}
                    src={consumidor}
                    height="50px"
                    alt="icon"
                  />
                  <p>Cliente</p>
                </button>
                <button
                  className={`${styles.btnUser} ${
                    active.tienda && styles.active
                  }`}
                  type="submit"
                  id="account"
                  onClick={() => {
                    setRegisterType("tienda");
                    setActive({ consumidor: false, tienda: true });
                  }}
                >
                  <img
                    className={styles.tienda}
                    src={tienda}
                    height="40px"
                    alt="icon"
                  />
                  <p>Negocio</p>
                </button>
              </div>
              <button
                className={styles.btn}
                // disabled={
                //   input.contraseña.length < 1 ||
                //   input.contraseña2.length < 1 ||
                //   input.negocio.length < 1 ||
                //   input.usuario.length < 1 ||
                //   errors.contraseña ||
                //   errors.contraseña2 ||
                //   errors.negocio ||
                //   errors.usuario
                // }
                onClick={openForm2}
              >
                Siguiente
              </button>
              {/* </form> */}
            </div>
          </>
        ) : (
          <>
            <div className={styles.form2}>
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                {/* --------error popup---------- */}
                {globalState.error && (
                  <Error msg="An error occurred while validating the data" />
                )}
                <img
                  className={styles.blikzlogo1}
                  src={blikz}
                  alt="Blikz"
                  height="45px"
                />
                <div className={styles.input}>
                  {registerType === "tienda" ? (
                    <label>Nombre de tu negocio:</label>
                  ) : (
                    <label>Nombre completo:</label>
                  )}
                  <input
                    className={styles.input}
                    type="text"
                    value={input.negocio}
                    name="negocio"
                    placeholder={
                      registerType === "tienda"
                        ? "Ingresa el nombre de tu negocio"
                        : "Ingresa tu nombre completo"
                    }
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorsHandler(e)}
                  />{" "}
                  {errors && <small>{errors.negocio}</small>}
                </div>
                {/* <br /> */}

                <div className={styles.inputDiv}>
                  <label>Nombre de usuario:</label>
                  <input
                    className={styles.input}
                    type="text"
                    value={input.usuario}
                    name="usuario"
                    placeholder="Ingresa un nombre de usuario"
                    onChange={(e) => {
                      handleUserName(e);
                    }}
                    onBlur={(e) => errorsHandler(e)}
                  />{" "}
                  {errors && <small>{errors.usuario}</small>}
                </div>
                <br />
                <div className={styles.inputDiv}>
                  <label className={styles.label}>Crea una contraseña</label>
                  <input
                    className={styles.input}
                    type="password"
                    value={input.contraseña}
                    name="contraseña"
                    placeholder="Crea una contraseña"
                    onChange={(e) => {
                      handleChangeMinuscula(e);
                    }}
                    onBlur={(e) => errorsHandler(e)}
                  />{" "}
                  <br />
                  {errors && <small>{errors.contraseña}</small>}
                </div>
                <div className={styles.inputDiv}>
                  <label className={styles.label}>Repite tu contraseña</label>
                  <input
                    className={styles.input}
                    type="password"
                    value={input.contraseña2}
                    name="contraseña2"
                    placeholder="Confirma tu contraseña"
                    onChange={(e) => {
                      handleChangeMinuscula(e);
                    }}
                    onBlur={(e) => errorsHandler(e)}
                  />{" "}
                  <br />
                  {errors && <small>{errors.contraseña2}</small>}
                </div>
                {/* {/* <div className={styles.input}>
              <div className={styles.logo}>
                {!image[0] ? (
                  <img src={logo} alt="Tu Logo" height="100px" width="150px" />
                ) : (
                  <img src={image} alt="Tu Logo" height="100px" width="130px" />
                )}
              </div>

              <input
                className={styles.inputimg}
                hidden
                name="imagen"
                value={input.imagen}
                type="file"
                multiple
                onChange={changeInput}
              ></input>
              {errors && <small>{errors.imagen}</small>}
            </div> */}

                <div className={styles.inputDiv}>
                  {registerType === "tienda" ? (
                    <label>Mail de tu negocio:</label>
                  ) : (
                    <label>Mail:</label>
                  )}
                  <input
                    className={styles.input}
                    type="email"
                    value={input.email}
                    name="email"
                    placeholder="Ingresa un mail"
                    onChange={(e) => {
                      handleChangeMinuscula(e);
                    }}
                    onBlur={(e) => errorsHandler(e)}
                  />{" "}
                  {/* <br /> */}
                  {errors && <small>{errors.email}</small>}
                </div>
                <br />
                <div className={styles.input}>
                  {registerType === "tienda" ? (
                    <label>Dirección de tu negocio:</label>
                  ) : (
                    <label>Tu dirección:</label>
                  )}
                  <Autocomplete
                    onLoad={(autocomplete) =>
                      handleAutocompleteLoad(autocomplete)
                    }
                  >
                    <input
                      className={styles.input}
                      type="text"
                      value={input.direccion}
                      name="direccion"
                      placeholder="Esta será tu dirección de retiro"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={(e) => errorsHandler(e)}
                    />
                  </Autocomplete>{" "}
                  {errors && <small>{errors.direccion}</small>}
                </div>
                <br />

                <div className={styles.input}>
                  {registerType === "tienda" ? (
                    <label>Teléfono de tu negocio:</label>
                  ) : (
                    <label>Teléfono:</label>
                  )}
                  <input
                    className={styles.input}
                    type="text"
                    value={input.telefono}
                    name="telefono"
                    placeholder="Este será tu número de contacto"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => errorsHandler(e)}
                  />{" "}
                  <br />
                  {errors && <small>{errors.telefono}</small>}
                </div>
                <div className={styles.divButtons}>
                  <button className={styles.btn2} onClick={(e) => openForm1(e)}>
                    Atras
                  </button>
                  <button
                    className={styles.btn2}
                    disabled={
                      input.email.length < 1 ||
                      // input.direccion.length < 1 ||
                      input.telefono.length < 1 ||
                      errors.email ||
                      // errors.direccion ||
                      errors.telefono
                    }
                    type="submit"
                  >
                    Crear Usuario
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
}
