import React from "react";
import styles from "./ProgressBar.module.css";
import repartidor from "../../../../assets/LandingNegocio/repartidor.png";
import casa from "../../../../assets/Map/casa.png";
import negocio from "../../../../assets/Map/tienda.png";

const ProgressBar = ({ caseType }) => {
  return (
    <>
      {caseType === 1 && (
        <div className={styles.gral}>
          <div className={styles.module}>
            <img
              className={styles.repartidorImg}
              src={repartidor}
              alt="repartidor"
              height="55px"
            />
            <div className={styles.progressBar}>
              <div className={styles.progress}></div>
            </div>
            <h3 className={styles.repartidor}>Repartidor</h3>
          </div>
          <div className={styles.module}>
            <img
              className={styles.negocio}
              src={negocio}
              alt="negocio"
              height="43px"
            />
            <div className={styles.progressBar}></div>
            <h3 className={styles.retiro}>Retiro</h3>
          </div>
          <div className={styles.module}>
            <img className={styles.casa} src={casa} alt="casa" height="43px" />
            <div className={styles.progressBar}></div>
            <h3 className={styles.entrega}>Entrega</h3>
          </div>
        </div>
      )}
      {caseType === 2 && (
        <div className={styles.gral}>
          <div className={styles.progressBar}></div>
          <div className={styles.progressBar}>
            <div className={styles.progress}></div>
          </div>
          <div className={styles.progressBar}></div>
        </div>
      )}
      {caseType === 3 && (
        <div className={styles.gral}>
          <div className={styles.progressBar}></div>
          <div className={styles.progressBar}></div>
          <div className={styles.progressBar}>
            <div className={styles.progress}></div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProgressBar;
