import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { getUserInfo } from "../../redux/actions";
import styles from "./Login.module.css";
import { errorModal, loading, login } from "../../redux/actions";
import Loader from "../Loader/Loader";
import Error from "./ErrorPopUp/Error";
import loginValidate from "./controllers/loginValidator";
import logo from "../../assets/logo.png";
import show from "../../assets/show.png";
import hide from "../../assets/hide.png";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const [errors, setErrors] = useState({});
  const [showPass, setShowPass] = useState(false);
  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  function handleChange(e) {
    setInput({
      ...input, //ademas de lo que tiene
      [e.target.name]: e.target.value.toLowerCase(), //agregale lo que el usuario pone
    });
  }

  function loginf() {
    window.location.reload();
    navigate("/admin");
  }

  function handleSubmit(e) {
    e.preventDefault();

    //si ambos campos están vacíos
    if (input.email === "" || input.password === "") {
      return setErrors(loginValidate(input));
    }

    //si las props de error poseen algun valor, haveError será true
    const haveError = Object.values(errors).some((v) => v !== undefined);

    if (haveError === false) {
      localStorage.setItem(
        "Mail-Usuario-Logueado",
        JSON.stringify(input.email)
      );
      loading(dispatch, true);
      const credential = login(dispatch, input.email, input.password);
      credential.then((re) => {
        re ? loginf() : errorModal(dispatch, true);
      });
      // alert("Loading...");
    } else alert("There are still errors in the fields");
  }

  // if(!globalState.login){
  //   localStorage.removeItem("Mail-Usuario-Logueado");
  //   }

  function errorsHandler(e) {
    // console.log("errorH", e.target.name);
    let form = { [e.target.name]: input[e.target.name] };
    let fails = loginValidate(form);
    setErrors((prev) => ({ ...prev, ...fails }));
  }

  useEffect(() => {
    login(dispatch)
      .then((res) => {
        res ? navigate("/admin") : console.log("data.login es false", res);
      })
      .catch((err) => console.log("Error en useEffect Login", err));
    loading(dispatch, false);
  }, [dispatch, navigate]);

  return (
    <div className={styles.gral}>
      {/* ---------loader-------- */}
      {globalState.loading.login && <Loader />}
      <div className={styles.form}>
        <img src={logo} alt="Blikz" height="45px" />
        <h1 className={styles.title}>Iniciar sesión</h1>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          {/* --------error popup---------- */}
          {globalState.error && (
            <Error msg="An error occurred while validating the data" />
          )}
          <div className={styles.input}>
            {/* <label c>Email</label> */}
            <input
              className={styles.input}
              type="text"
              value={input.email}
              name="email"
              placeholder="Ingresar correo electrónico"
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => errorsHandler(e)}
            />{" "}
            <br />
            {errors && <small>{errors.email}</small>}
          </div>
          <br />
          <div>
            {/* <label>Password</label> */}
            <div className={styles.iconContainer}>
              <input
                className={styles.input}
                type={showPass ? "text" : "password"}
                value={input.password}
                name="password"
                placeholder="Ingresar contraseña"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={(e) => errorsHandler(e)}
              />{" "}
              <br />
              {errors && <small>{errors.password}</small>}
              <img
                src={showPass ? show : hide}
                alt={showPass ? "Hide" : "Show"}
                className={styles.icon}
                onClick={() => setShowPass(!showPass)}
              />
            </div>

            <div className={styles.restPassword}>
              {/* <Link className={styles.link} to="/restorePassword">
                ¿Olvidaste la contraseña?
              </Link> */}
              <div className={styles.registrate}>
                ¿No tienes cuenta aún?
                <Link className={styles.linkRegistro} to="/registrarme">
                  Registrate.
                </Link>
              </div>
            </div>
          </div>
          <button
            className={styles.btn}
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Ingresar
          </button>
        </form>
      </div>
    </div>
  );
}
