export const saveToken = (token) => {
  if (token) {
    localStorage.setItem("token", token);
  }
};
export const getToken = () => {
  return localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "No se ha encontrado el token en localStorage";
};

export const deleteToken = () => {
  if (localStorage.getItem("token").length) {
    localStorage.removeItem("token");
  }
  return "No se puede eliminar algo que no existe en localStorage";
};
