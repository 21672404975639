import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./PanelDeBlikz.module.css";
import { Howl } from "howler";
import {
  getAllOrdersPanel,
  retiredOrder,
  transportedBy,
  getAllUsers,
  // updateOrderCost,
} from "../../redux/actions";
import logo from "../../assets/logoTienda.png";
import logoBlikz from "../../assets/logo.png";
import notificacion from "../../assets/NuevoPedidoBlikz.mp3";
import InfoPedido from "./InfoPedido";
// import io from "socket.io-client";

// const WebSocketHandler = () => {
//   const dispatch = useDispatch();

//   return null; // Puedes devolver un elemento vacío o personalizarlo según tus necesidades
// };

const PanelDeBlikz = () => {
  const allOrders = useSelector((state) => state.allOrdersPanel);
  const allUsers = useSelector((state) => state.allUsers);
  const newOrder = useSelector((state) => state.newOrder);
  const [btnChange, setBtnChange] = useState(false);
  const [idN, setIdN] = useState(1);
  // const [precioEnvio, setPrecioEnvio] = useState(0);
  // const [ordersAllNew, setOrdersAllNew] = useState([]);
  const [prevAllOrdersLength, setPrevAllOrdersLength] = useState(
    allOrders[0] ? allOrders[0].length : 0
  );
  const redirectMsg = (numero, negocio, id) => {
    const mensaje = `Hola, estás por recibir un pedido de ${negocio}. Puedes seguirlo en el siguiente enlace: https://app.getblikz.com/#/seguimiento/${id}`
    const mensajeCodificado = encodeURIComponent(mensaje);
    window.open(`https://wa.me/52${numero}?text=${mensajeCodificado}`);
  };
  const dispatch = useDispatch();
  const notiSound = new Howl({
    src: [notificacion],
  });

  useEffect(() => {
    if (!allOrders[0] && !allUsers[0]) {
      dispatch(getAllOrdersPanel());
      dispatch(getAllUsers());
    }
    if (allOrders[0] && newOrder) {
      // setOrdersAllNew(allOrders[0])
      // setTimeout(() => {
      dispatch(getAllOrdersPanel());
      // }, 15000);

      if (allOrders[0].length > prevAllOrdersLength) {
        // notiSound.play();
        sound();
        setPrevAllOrdersLength(allOrders[0].length);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrders, newOrder]);

  const sound = () => {
    let button = document.getElementById("buttonPedido");
    button.onClick = notiSound.play();
    button.onClick = () => notiSound.play();
  };

  function handleSelect(e, id, index) {
    if (e.target.value.length > 1) {
      let select = document.getElementById(`${index + "s"}`);
      select.disabled = true;
      let estado = "Repartidor en camino";
      dispatch(retiredOrder(id, estado));
      dispatch(transportedBy(id, e.target.value));
    }
  }

  // function handleInput(e, index) {
  //   if (e.target.value) {
  //     let input = document.getElementById(`${index + "i"}`);
  //     input.value = e.target.value;
  //     setPrecioEnvio(e.target.value);
  //   }
  // }

  // function cotizarCostoEnvio(id) {
  //   dispatch(updateOrderCost(id, precioEnvio));
  // }

  function handleRetirado(id, index) {
    let button = document.getElementById(`${index}`);
    button.disabled = true;
    let estado = "Retirado";
    dispatch(retiredOrder(id, estado));
  }

  function handleEntregado(id, index) {
    let button = document.getElementById(`${index + "a"}`);
    button.disabled = true;
    let estado = "Entregado";
    dispatch(retiredOrder(id, estado));
  }

  const idOpen = (id) => {
    setBtnChange(true);
    setIdN(id);
  };

  return (
    <div className={styles.gral}>
      {/* <WebSocketHandler /> Componente para manejar los WebSockets */}
      {/* Resto del código del componente PanelDeBlikz */}
      <InfoPedido
        trigger={btnChange}
        setTrigger={setBtnChange}
        idOrder={idN}
      ></InfoPedido>
      <button className={styles.buttonOff} id="buttonPedido">
        PEDIDO
      </button>
      <img src={logoBlikz} alt="LogoBlikz" height="50px" />
      <h2>Pedidos en curso</h2>
      {allOrders[0] && allUsers[0] ? (
        allOrders[0]
          .filter(
            (el) => el.status !== "Entregado" && el.cancelado !== "cancelado"
          )
          .sort((a, b) => a.id - b.id)
          .map((e, index) => (
            <div className={styles.container} key={index}>
              <img src={logo} alt="LogoTienda" height="45px" />
              <h3>
                {allUsers[0]
                  ? allUsers.find((el) => el.id === e.clientId).negocio
                  : ""}
              </h3>
              <button
                className={styles.buttonPedido}
                onClick={() => idOpen(e.id)}
              >{`Pedido #${e.id}`}</button>
              {/* {e.cancelado === "aceptado" ? (
                <h3>Aceptado</h3>
              ) : (
                <div className={styles.inputPrecio}>
                  <input
                    id={index + "i"}
                    type="number"
                    name="pagado"
                    placeholder="Asignar precio"
                    disabled={e.costoEnvio !== 0 ? true : false}
                    onChange={(e) => handleInput(e, index)}
                    className={styles.precioEnvio}
                  />
                  <button
                    id={index + "b"}
                    onClick={() => cotizarCostoEnvio(e.id, index)}
                    disabled={e.costoEnvio > 0 ? true : false}
                  >
                    Cotizar
                  </button>
                </div>
              )} */}
              <button
                onClick={() =>
                  redirectMsg(
                    e.telefonoCliente,
                    allUsers.find((el) => el.id === e.clientId).negocio,
                    e.id
                  )
                }
                className={styles.buttonWs}
              >
                Seguimiento
              </button>
              <select
                id={index + "s"}
                onChange={(ev) => handleSelect(ev, e.id, index)}
                disabled={e.status !== "Buscando Repartidor"}
              >
                <option value="" selected>
                  {e.repartidor ? e.repartidor : "Asignar repartidor"}
                </option>
                <option value="El Duki">Ramiro</option>
                <option value="Leo Messi">Agustin</option>
              </select>
              {/* <button 
               className={styles.whatsapp}
            >Enviar por WhatsApp</button> */}
              <button
                id={index}
                onClick={() => handleRetirado(e.id, index)}
                className={styles.containerButton}
                disabled={e.status === "Retirado"}
              >
                Pedido Retirado
              </button>
              <button
                id={index + "a"}
                onClick={() => handleEntregado(e.id, index)}
                className={styles.containerButton}
                disabled={e.status === "Entregado"}
              >
                Pedido Entregado
              </button>
            </div>
          ))
      ) : (
        <div className={styles.container}>
          <h2>No hay pedidos en curso . . .</h2>
        </div>
      )}
      <h2>Pedidos finalizados</h2>
      {allOrders[0] && allUsers[0] ? (
        allOrders[0].filter(
          (el) => el.status === "Entregado" && el.cancelado !== "cancelado"
        ).length > 0 ? (
          allOrders[0]
            .filter(
              (el) => el.status === "Entregado" && el.cancelado !== "cancelado"
            )
            .sort((a, b) => a.id - b.id)
            .map((pedidoListo, index) => (
              <div className={styles.container1} key={index}>
                <div className={styles.tienda_div1}>
                  <img src={logo} alt="LogoTienda" height="45px" />
                  <h3>
                    {allUsers[0]
                      ? allUsers.find((el) => el.id === pedidoListo.clientId)
                          .negocio
                      : ""}
                  </h3>
                  <h4>{`Pedido #${pedidoListo.id}`}</h4>
                  <button onClick={() => idOpen(pedidoListo.id)}>
                    Ver más
                  </button>
                  <h4>{pedidoListo.status}</h4>
                </div>
              </div>
            ))
        ) : (
          <div className={styles.container}>
            <h2>No hay pedidos finalizados . . .</h2>
          </div>
        )
      ) : (
        <div className={styles.container}>
          <h2>No hay pedidos finalizados . . .</h2>
        </div>
      )}
    </div>
  );
};

export default PanelDeBlikz;
